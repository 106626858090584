import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import uiReducer from './uiSlice';
import sitesReducer from './financialSlice';
import chargersReducer from './chargerSlice';
import healthReducer from './healthSlice';

export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable the serializability check
    }),

  /*  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['sites/setReduxSites', 'chargers/getSites/fulfilled'],
        // Ignore these paths in the state
        ignoredPaths: ['sites', 'payload'],
      },
    }), */
  reducer: {
    user: userReducer,
    ui: uiReducer,
    sites: sitesReducer,
    chargers: chargersReducer,
    health: healthReducer,
  },
});
