import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ReactComponent as EVCicon } from '../../src/images/powered_by_evc.svg';
import { AuthRepository } from '../repositories/auth_repository/auth_repository';

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const navigate = useNavigate();

  const isDev = process.env.REACT_APP_ENV === 'development';

  const handleNewPasswordChange = (password) => {
    setFormErrors((prevState) => {
      const { passwords, ...newState } = prevState;
      return newState;
    });
    setNewPassword(password);
  };

  const handleRepeatPasswordChange = (password) => {
    setFormErrors((prevState) => {
      const { passwords, ...newState } = prevState;
      return newState;
    });
    setRepeatPassword(password);
  };

  const handleFirstNameChange = (firstName) => {
    setFormErrors((prevState) => {
      const { firstName, ...newState } = prevState;
      return newState;
    });
    setFirstName(firstName);
  };

  const handleLastNameChange = (lastName) => {
    setFormErrors((prevState) => {
      const { lastName, ...newState } = prevState;
      return newState;
    });
    setLastName(lastName);
  };

  const handleEmailChange = (email) => {
    setFormErrors((prevState) => {
      const { email, ...newState } = prevState;
      return newState;
    });
    setEmail(email);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();
    let isError = false;
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
    const validEmailRegex = /^[a-zA-Z0-9._\+\-]+@[a-zA-Z0-9.\-\+]+\.[a-zA-Z]{2,4}$/;
    setIsLoading(true);

    //required fields
    if (!firstName || !lastName || !email || !newPassword || !repeatPassword) {
      isError = true;
      !firstName &&
        setFormErrors((prevState) => ({ ...prevState, firstName: 'First name is required' }));
      !lastName &&
        setFormErrors((prevState) => ({ ...prevState, lastName: 'Last name is required' }));
      !email && setFormErrors((prevState) => ({ ...prevState, email: 'Email is required' }));
      (!newPassword || !repeatPassword) &&
        setFormErrors((prevState) => ({ ...prevState, passwords: 'A password is required' }));
    }

    if (!validEmailRegex.test(email)) {
      setFormErrors((prevState) => ({ ...prevState, email: 'Not a valid email address' }));
      isError = true;
    }

    if (!passwordRegex.test(newPassword) || !passwordRegex.test(repeatPassword)) {
      setFormErrors((prevState) => ({
        ...prevState,
        passwords:
          'The password must contain at least: \nEight characters, \nOne number, \nOne uppercase letter, \nOne non-alphanumeric character',
      }));
      isError = true;
    }

    if (newPassword !== repeatPassword) {
      setFormErrors((prevState) => ({ ...prevState, passwords: 'Passwords do not match' }));
      isError = true;
    }

    if (!isError) {
      try {
        const response = await AuthRepository.registerClientPortalUser(
          firstName,
          lastName,
          email,
          newPassword,
        );
        toast.success(
          'Your registration has been submitted. A member of the EVC support team has been notified of you request and will update your account status at their earliest opportunity.',
        );
        if (response.statusCode === 200 && response.msg === 'User has been promoted successfully') {
          toast.success('The password you have just set will used for your existing EVC App user');
        }
        navigate('/sign-in');
      } catch (error) {
        if (error.response.data.code === 'portal_user_already_exists') {
          setFormErrors((prevState) => ({
            ...prevState,
            email: 'This user has been previously used to register.',
          }));
          setIsLoading(false);
        } else {
          toast.error('Something has gone wrong - Please try again or contact support');
          setIsLoading(false);
        }
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-primary-background">
      <div className="flex flex-col justify-center h-screen bg-center bg-no-repeat bg-cover place-items-center bg-journey-image brightness-50">
        <div className="relative"></div>
      </div>
      <div className="w-[400px] p-[20px] pt-[24px] bg-white rounded-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <h1 className="flex mb-4 text-3xl font-[600] text-[28px]">Charge Portal Registration</h1>

        <form className="grid flex-col" onSubmit={submitFormHandler}>
          <div className="flex">
            <FormControl
              sx={{ marginBottom: '12px', marginRight: '12px' }}
              className="w-1/2 pt-3 mb-6"
              variant="outlined"
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-firstName">First Name*</InputLabel>
              <OutlinedInput
                error={!!formErrors?.firstName}
                fullWidth
                id="outlined-adornment-firstName"
                type="text"
                label="first-name"
                value={firstName || ''}
                onChange={(e) => handleFirstNameChange(e.target.value.trim())}
              />
              {formErrors?.firstName && (
                <p className="text-xs text-red-700">{formErrors?.firstName}</p>
              )}
            </FormControl>

            <FormControl
              sx={{ marginBottom: '12px' }}
              className="w-1/2 pt-3 mb-6"
              variant="outlined"
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-lastName">Last Name*</InputLabel>
              <OutlinedInput
                error={!!formErrors?.lastName}
                fullWidth
                id="outlined-adornment-lastName"
                type="text"
                label="last-name"
                value={lastName || ''}
                onChange={(e) => handleLastNameChange(e.target.value.trim())}
              />
              {formErrors?.lastName && (
                <p className="text-xs text-red-700">{formErrors?.lastName}</p>
              )}
            </FormControl>
          </div>
          <FormControl
            sx={{ marginBottom: '12px' }}
            className="pt-3 mb-6"
            variant="outlined"
            fullWidth
          >
            <InputLabel htmlFor="outlined-adornment-email">Email*</InputLabel>
            <OutlinedInput
              error={!!formErrors?.email}
              fullWidth
              id="outlined-adornment-email"
              type="text"
              label="email"
              value={email || ''}
              onChange={(e) => handleEmailChange(e.target.value.trim())}
            />
            {formErrors?.email && <p className="text-xs text-red-700">{formErrors?.email}</p>}
          </FormControl>

          <FormControl
            sx={{ marginBottom: '12px' }}
            className="pt-3 mb-6"
            variant="outlined"
            fullWidth
          >
            <InputLabel htmlFor="outlined-adornment-new-password">Password*</InputLabel>
            <OutlinedInput
              error={!!formErrors?.passwords}
              fullWidth
              id="outlined-adornment-new-password"
              type={showNewPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showNewPassword ? (
                      <VisibilityOff sx={{ color: '#2F9638' }} />
                    ) : (
                      <Visibility sx={{ color: '#2F9638' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="password"
              value={newPassword || ''}
              onChange={(e) => handleNewPasswordChange(e.target.value.trim())}
            />
          </FormControl>

          <FormControl className="mt-3" variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">Confirm Password*</InputLabel>
            <OutlinedInput
              error={!!formErrors?.passwords}
              fullWidth
              id="outlined-adornment-password"
              type={showRepeatPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showRepeatPassword ? (
                      <VisibilityOff sx={{ color: '#2F9638' }} />
                    ) : (
                      <Visibility sx={{ color: '#2F9638' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="confirm-password"
              value={repeatPassword || ''}
              onChange={(e) => handleRepeatPasswordChange(e.target.value.trim())}
            />
          </FormControl>

          {formErrors?.passwords && <p className="text-xs text-red-700">{formErrors?.passwords}</p>}

          <div className="w-full pt-3 mt-[12px]">
            <Button
              sx={{ borderRadius: 8, height: '40px', width: '100%' }}
              variant="contained"
              color="success"
              type="submit"
              disabled={isLoading}
            >
              Register
            </Button>
          </div>
          <div className="inline-flex justify-center mt-7">
            <span>
              <p className="mb-4 text-base font-bold text-md">Powered by</p>
            </span>
            <span className="pt-1 mb-4 ml-3 font-bold text-md" style={{ marginTop: '-1px' }}>
              <EVCicon />
            </span>
            {isDev && <span className="mb-4 ml-3 font-bold text-md">[DEV]</span>}
          </div>
          <Link
            sx={{ marginBottom: '12px', fontSize: '14px' }}
            className="flex justify-center"
            onClick={() => navigate('/sign-in')}
            color="#24712A"
          >
            {'Already have an account? Sign in'}
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Register;
