/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Loader } from '../../common/Loader';

const SiteTransactionsLocal = (props) => {
  const { isLoadingFinancial, selectedFinancialSite } = useSelector((state) => state.sites);
  const { selectedQuarterLabel } = useSelector((state) => state.ui);
  const { qToShow } = props;
  const [sessions, setSessions] = useState([]);
  let fileNameToUse = 'EVC Plus Portal Data Export';

  const canOverride = useSelector((state) => state.user.user.pid === 'M110QfzAZ1yxPfqFqT6X');
  const isManagedService = selectedFinancialSite.siteInfo.model === 'Managed Service';


  const columns = [
    {
      name: 'Date',
      selector: (row) => row.date,
      cellExport: (row) => row.date,
      width: '130px',
      //sortable: true
    },
    {
      name: 'Time',
      selector: (row) => row.time,
      cellExport: (row) => row.time,
      width: '130px',
    },

    {
      name: 'Charger',
      selector: (row) => row.charger,
      cellExport: (row) => row.charger,
      width: '300px',
    },
    {
      name: 'Outlet',
      selector: (row) => row.socket,
      cellExport: (row) => row.socket,
      width: '80px',
    },
    {
      name: 'kWh',
      selector: (row) => row.kwh,
      cellExport: (row) => row.kwh,
      width: '80px',
    },
    {
      name: 'Charging duration',
      selector: (row) => row.duration,
      cellExport: (row) => row.duration,
      width: '150px',
      format: (row) => moment.utc(row.duration * 1000 * 60).format('HH:mm:ss'),
    },
  ];

  if (selectedFinancialSite.siteInfo.displayRevenue || (canOverride && isManagedService)) {
    columns.push({
      name: 'Revenue',
      selector: (row) => '£' + row.revenue,
      cellExport: (row) => row.revenue,
    });
  }

  const tableData = {
    columns,
    data: sessions,
  };

  const setTableData = useCallback(() => {
    const totalSiteSessions = [];
    const transactionsToEvaluate = qToShow.financialData?.transactions ?? [];
    const allChargers = selectedFinancialSite?.siteInfo.chargepoints;
    const allChargersSockets = allChargers.map((e) => e.sockets).flat();
    const selectedChargerSocketsIds = selectedFinancialSite?.selectedCharger?.sockets.map((e) => e.id);
    const selectedSocketId = selectedFinancialSite?.selectedSocket?.id;

    for (const tx of transactionsToEvaluate) {
      let chargerToUse = null;
      for (const socket of allChargersSockets) {
        if (socket.id === tx.chargerId) {
          chargerToUse = allChargersSockets.find((e) => e.id === tx.chargerId);
        }
      }

      if (selectedChargerSocketsIds && !selectedChargerSocketsIds.includes(tx.chargerId)) continue;
      if (selectedSocketId && selectedSocketId !== tx.chargerId) continue;

      let revenue = 0;
      let kwh = null;
      if (tx.externalData) {
        revenue = tx.externalData.calculatedCost;
        kwh = parseFloat(tx.externalData?.reportedKwh ?? 0);
      } else {
        revenue = tx.chargerStats?.calculatedCost;
        kwh = parseFloat(tx.chargerStats?.kwh ?? '0');
      }
      const chargingDuration = (tx.chargerStats?.durationInSeconds ?? 0.0) / 60;

      let sessionRecord = {
        id: tx.id,
        date: new Date(tx.startCharger?.timestampInSecs).toLocaleDateString('en-GB'),
        dateForSorting: new Date(tx.startCharger?.timestampInSecs),
        time: new Date(tx.startCharger?.timestampInSecs).toLocaleTimeString('en-GB'),
        user: tx.email,
        charger: chargerToUse?.charger_name ?? 'No charger',
        socket: chargerToUse?.outlet ?? 'No socket',
        kwh: kwh.toFixed(2) ?? 'Not processed',
        duration: chargingDuration.toFixed(1),
        revenue: revenue.toFixed(2),
      };
      totalSiteSessions.push(sessionRecord);
    }

    totalSiteSessions.sort((a, b) => b.dateForSorting - a.dateForSorting);

    setSessions(totalSiteSessions);
  }, [
    qToShow.financialData?.transactions,
    selectedFinancialSite?.selectedCharger?.sockets,
    selectedFinancialSite?.selectedSocket?.id,
    selectedFinancialSite?.siteInfo.chargepoints,
  ]);

  useEffect(() => {
    setTableData();
  }, [selectedFinancialSite, setTableData]);

  if (!selectedFinancialSite.selectedCharger && !selectedFinancialSite.selectedSocket) {
    fileNameToUse = selectedFinancialSite.siteInfo.siteName ;
  } else if (selectedFinancialSite.selectedCharger && !selectedFinancialSite.selectedSocket) {
    fileNameToUse = selectedFinancialSite.selectedCharger.name;
  } else if (selectedFinancialSite.selectedCharger && selectedFinancialSite.selectedSocket ) {
    fileNameToUse =
      selectedFinancialSite.selectedSocket.charger_name + ' ' + selectedFinancialSite.selectedSocket.manual_code ;
  }
  
  if(selectedQuarterLabel) {
    fileNameToUse = fileNameToUse + ' ' + selectedQuarterLabel;
  }



  if (!selectedFinancialSite.selectedCharger && !selectedFinancialSite.selectedSocket) {
    fileNameToUse = selectedFinancialSite.siteInfo.siteName ;
  } else if (selectedFinancialSite.selectedCharger && !selectedFinancialSite.selectedSocket) {
    fileNameToUse = selectedFinancialSite.selectedCharger.name;
  } else if (selectedFinancialSite.selectedCharger && selectedFinancialSite.selectedSocket ) {
    fileNameToUse =
      selectedFinancialSite.selectedSocket.charger_name + ' ' + selectedFinancialSite.selectedSocket.manual_code ;
  }
  
  if(selectedQuarterLabel) {
    fileNameToUse = fileNameToUse + ' ' + selectedQuarterLabel;
  }



  return (
    <div className="w-full">
      {isLoadingFinancial ? (
        <Loader />
      ) : (
        <div className="pl-4">
          <DataTableExtensions {...tableData} fileName={fileNameToUse.split(' ').join('_').toLowerCase()}>
            <DataTable
              noHeader
              defaultSortField="id"
              defaultSortAsc={false}
              pagination
              highlightOnHover
              customStyles={{
                rows: {
                  style: {
                    fontSize: '16px',
                  },
                },
              }}
            />
          </DataTableExtensions>
        </div>
      )}
    </div>
  );
};

export default SiteTransactionsLocal;
