import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Portal from './Portal';
import PendingAccount from '../components/PendingAccount/PendingAccount';

const LandingPage = () => {
  const [showPortal, setShowPortal] = useState();
  const { sid, pid } = useSelector((state) => state.user.user);

  useEffect(() => {
    if (!sid && !pid) {
      setShowPortal(false);
    } else {
      setShowPortal(true);
    }
  }, [sid, pid]);

  if (!showPortal) {
    return <PendingAccount />;
  } 
  
  return <Portal />;
  
};

export default LandingPage;
