import React from 'react';
import PropTypes from 'prop-types';

export default function StatusKeyItem({ chargerStatus, keyDescription }) {
  let statusColor = '';

  if (!chargerStatus) {
    return (
      <div className="flex flex-row my-2">
        <div
          style={{
            width: 24,
            height: 24,
          }}
        />
        {keyDescription && <p className="pl-2">{keyDescription}</p>}
      </div>
    );
  }

  switch (chargerStatus) {
    case 'AVAILABLE':
      statusColor = '#2F9638';
      break;
    case 'PREPARING':
    case 'SUSPENDEDEV':
    case 'FINISHING':
      statusColor = '#C28B00';
      break;
    case 'CHARGING':
      statusColor = '#0400CB';
      break;
    case 'FAULTED':
    case 'SUSPENDEDEVSE':
    case 'UNAVAILABLE':
    case 'UNKOWN':
    case 'EVSE_NOT_FOUND':
      statusColor = '#DC362E';
      break;
    default:
      break;
  }

  return (
    <div className="flex flex-row my-2">
      <div
        style={{
          width: 24,
          height: 24,
          background: statusColor,
          borderRadius: '50%',
        }}
      />
      {keyDescription && <p className="pl-2">{keyDescription}</p>}
    </div>
  );
}

StatusKeyItem.propTypes = {
  chargerStatus: PropTypes.string,
  keyDescription: PropTypes.string,
};
