import { signOut } from 'firebase/auth';

import auth from '../firebase';

export const Header = () => {
  const isDev = process.env.REACT_APP_ENV === 'development';
  return (
    <div className="flex pt-10 pb-5">
      <div className="flex-wrap pl-16 ">
        <h1 className="pt-6 pb-3 text-3xl font-semibold text-black font-custom-bold">
          Charge Portal {isDev ? 'DEV' : ''}
        </h1>
        <div className="pb-2 text-base font-extralight">
          View all operational information regarding EVC chargers
        </div>
        {/* <div className="pt-3 ">
          <NavButtons />
        </div> */}
      </div>
      <div
        className="pr-10 ml-auto text-sm cursor-pointer pt-11 text-sign-out hover:text-black-text"
        onClick={() => {
          signOut(auth);
        }}
      >
        Sign out
        {/* <AccountOptions /> THIS IS THE ADMIN LOGIN TO BE USED LATER*/}
      </div>
    </div>
  );
};
