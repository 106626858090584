import { useState, useEffect } from 'react';
import auth from '../firebase';
import { confirmPasswordReset } from 'firebase/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const toastOptions = {
    autoClose: 10000,
  };

  useEffect(() => {
    if (!searchParams.get('oobCode')) {
      navigate('/forgot-password');
    }
  }, [searchParams, navigate]);

  const handleNewPasswordChange = (password) => {
    setErrorMessage('');
    setNewPassword(password);
  };

  const handleRepeatPasswordChange = (password) => {
    setErrorMessage('');
    setRepeatPassword(password);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
    const oobCode = searchParams.get('oobCode');
    setIsLoading(true);

    if (newPassword !== repeatPassword) {
      setErrorMessage('Passwords do not match');
      setIsLoading(false);
      return;
    }

    if (!passwordRegex.test(newPassword) || !passwordRegex.test(repeatPassword)) {
      setErrorMessage(
        'The password must contain at least: \nEight characters, \nOne number, \nOne uppercase letter, \nOne non-alphanumeric character',
      );
      setIsLoading(false);
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      toast.success('Your password has been changed');
      navigate('/sign-in');
    } catch (error) {
      if (error.code === 'auth/expired-action-code') {
        toast.error(
          'This link has now expired. Please request a new Password Reset Link below',
          toastOptions,
        );
      } else if (error.code === 'auth/invalid-action-code') {
        toast.error(
          'This link is invalid or has already been used. Please request a new Password Reset Link below',
          toastOptions,
        );
      } else {
        toast.error(
          'Something has gone wrong - Please request a new Password Reset Link or contact support',
          toastOptions,
        );
      }
      setIsLoading(false);
      navigate('/forgot-password');
    }
  };

  return (
    <div className="bg-primary-background">
      <div className="flex flex-col justify-center h-screen bg-center bg-no-repeat bg-cover place-items-center bg-journey-image brightness-50">
        <div className="relative"></div>
      </div>
      <div className="w-[400px] p-[20px] pt-[24px] bg-white rounded-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <h1 className="flex mb-4 text-3xl font-[600] text-[28px]">Reset your password</h1>

        <form className="grid flex-col" onSubmit={submitFormHandler}>
          <FormControl
            sx={{ marginBottom: '12px' }}
            className="pt-3 mb-6"
            variant="outlined"
            fullWidth
          >
            <InputLabel htmlFor="outlined-adornment-new-password">New Password</InputLabel>
            <OutlinedInput
              fullWidth
              id="outlined-adornment-new-password"
              type={showNewPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showNewPassword ? (
                      <VisibilityOff sx={{ color: '#2F9638' }} />
                    ) : (
                      <Visibility sx={{ color: '#2F9638' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password"
              value={newPassword || ''}
              onChange={(e) => handleNewPasswordChange(e.target.value.trim())}
            />
          </FormControl>

          <FormControl className="mt-3" variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">Repeat Password</InputLabel>
            <OutlinedInput
              fullWidth
              id="outlined-adornment-password"
              type={showRepeatPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showRepeatPassword ? (
                      <VisibilityOff sx={{ color: '#2F9638' }} />
                    ) : (
                      <Visibility sx={{ color: '#2F9638' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Repeat Password"
              value={repeatPassword || ''}
              onChange={(e) => handleRepeatPasswordChange(e.target.value.trim())}
            />
          </FormControl>

          {errorMessage && <p className="text-xs text-red-700">{errorMessage}</p>}

          <div className="w-full pt-3 mt-[12px]">
            <Button
              sx={{ borderRadius: 8, height: '40px', width: '100%' }}
              variant="contained"
              color="success"
              type="submit"
              disabled={isLoading}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
