import { useEffect } from 'react';
import { onAuthStateChanged } from '@firebase/auth';
import { useDispatch, useSelector } from 'react-redux';

import auth from './firebase';
import { login, logout } from './store/userSlice';

import { setInitialUI } from './store/uiSlice';
import { clearFinancialSites } from './store/financialSlice';
import { clearHealthSites } from './store/healthSlice';

import SignInScreen from './pages/SignInScreen';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register';
import { ToastContainer, toast } from 'react-toastify';

import Portal from './pages/Portal';
import SitePortalCard from './components/SiteDetails/SitePortalCard';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Summary } from './components/Summary/Summary';
import { ThemeProvider } from '@mui/material/styles';
import themeEVC from './muiTheme_EVC';
import themeNCP from './muiTheme_NCP';
import Health from './components/Health/Health';
import LandingPage from './pages/LandingPage';

function App() {
  const user = useSelector((state) => state.user.user);
  const { themeSelect } = useSelector((state) => state.user);
  const location = useLocation();
  const currentPath = location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const protectedRoutes = ['/', '/site-detail', '/health'];
    const publicRoutes = ['/sign-in', '/forgot-password', '/reset-password', '/register'];

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        auth.currentUser.getIdTokenResult().then((token) => {
          if (token.claims.portal === true && token.claims.sid != null) {
            dispatch(
              login({
                uid: user.uid,
                email: user.email,
                token: token.token,
                sid: token.claims.sid,
              }),
            );
          } else if (token.claims.portal === true && token.claims.pid != null) {
            dispatch(
              login({
                uid: user.uid,
                email: user.email,
                token: token.token,
                pid: token.claims.pid,
              }),
            );
          } else {
            dispatch(
              login({
                uid: user.uid,
                email: user.email,
                token: token.token,
              }),
            );
            dispatch(clearFinancialSites());
            dispatch(setInitialUI());
            dispatch(clearHealthSites());
          }
        });
        if (publicRoutes.includes(currentPath)) {
          navigate('/');
        }
      } else {
        dispatch(logout());
        dispatch(clearFinancialSites());
        dispatch(setInitialUI());
        dispatch(clearHealthSites());
        if (protectedRoutes.includes(currentPath)) {
          navigate('/sign-in');
        }
      }
    });
    return unsubscribe;
  }, [dispatch, currentPath, navigate]);

  return (
    <ThemeProvider theme={themeSelect === 'NCP' ? themeNCP : themeEVC}>
      <div className="w-screen h-screen bg-primary-background overflow-x-clip">
        <ToastContainer />
        {!user ? (
          // routes available when user isnt signed in
          <Routes>
            <Route path="/sign-in" element={<SignInScreen />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<LandingPage />}>
              <Route path="/site-detail" element={<SitePortalCard />} />
              <Route path="/health" element={<Health />} />
              <Route exact path="/" element={<Summary />} />
            </Route>
          </Routes>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
