import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export default function DashboardLiveStatusCard() {
  const { chargersStatus } = useSelector((state) => state.chargers);
  const { selectedFinancialSite } = useSelector((state) => state.sites);
  const selectedChargePoint = selectedFinancialSite?.selectedCharger?.sockets?.map(
    (socket) => socket.id,
  );

  const allSockets = selectedFinancialSite?.siteInfo?.chargepoints?.map((e) => e.sockets).flat() ?? [];
  const selectedSocket = selectedFinancialSite?.selectedSocket?.id;

  let allAvailableQty = chargersStatus.filter((charger) => charger.status === 'AVAILABLE').length;
  let allChargingQty = chargersStatus.filter((charger) => charger.status === 'CHARGING').length;
  let allPreparingQty = chargersStatus.filter(
    (charger) =>
      charger.status === 'PREPARING' ||
      charger.status === 'SUSPENDEDEV' ||
      charger.status === 'FINISHING',
  ).length;

  let allFaultedQty = chargersStatus.filter(
    (charger) =>
      charger.status === 'FAULTED' ||
      charger.status === 'SUSPENDEDEVSE' ||
      charger.status === 'UNAVAILABLE' ||
      charger.status === 'UNKNOWN' ||
      charger.status === 'EVSE_NOT_FOUND',
  ).length;

  if (selectedSocket) {
    allAvailableQty = chargersStatus.filter(
      (charger) => charger.status === 'AVAILABLE' && charger.id === selectedSocket,
    ).length;
    allChargingQty = chargersStatus.filter(
      (charger) => charger.status === 'CHARGING' && charger.id === selectedSocket,
    ).length;
    allPreparingQty = chargersStatus.filter(
      (charger) =>
        (charger.status === 'PREPARING' ||
          charger.status === 'SUSPENDEDEV' ||
          charger.status === 'FINISHING') &&
        charger.id === selectedSocket,
    ).length;
    allFaultedQty = chargersStatus.filter(
      (charger) =>
        (charger.status === 'FAULTED' ||
          charger.status === 'SUSPENDEDEVSE' ||
          charger.status === 'UNAVAILABLE' ||
          charger.status === 'UNKNOWN' ||
          charger.status === 'EVSE_NOT_FOUND') &&
        charger.id === selectedSocket,
    ).length;
  } else if (selectedChargePoint) {
    allAvailableQty = chargersStatus.filter(
      (charger) => charger.status === 'AVAILABLE' && selectedChargePoint.includes(charger.id),
    ).length;
    allChargingQty = chargersStatus.filter(
      (charger) => charger.status === 'CHARGING' && selectedChargePoint.includes(charger.id),
    ).length;
    allPreparingQty = chargersStatus.filter(
      (charger) =>
        (charger.status === 'PREPARING' ||
          charger.status === 'SUSPENDEDEV' ||
          charger.status === 'FINISHING') &&
        selectedChargePoint.includes(charger.id),
    ).length;
    allFaultedQty = chargersStatus.filter(
      (charger) =>
        (charger.status === 'FAULTED' ||
          charger.status === 'SUSPENDEDEVSE' ||
          charger.status === 'UNAVAILABLE' ||
          charger.status === 'UNKNOWN' ||
          charger.status === 'EVSE_NOT_FOUND') &&
        selectedChargePoint.includes(charger.id),
    ).length;
  } else {
    const allSocketsIds = allSockets.map((socket) => socket.id).flat();
    allAvailableQty = chargersStatus.filter((charger) => charger.status === 'AVAILABLE' && allSocketsIds.includes(charger.id)).length;
    allChargingQty = chargersStatus.filter((charger) => charger.status === 'CHARGING' && allSocketsIds.includes(charger.id)).length;
    allPreparingQty = chargersStatus.filter(
      (charger) =>
        (charger.status === 'PREPARING' ||
          charger.status === 'SUSPENDEDEV' ||
          charger.status === 'FINISHING') &&
        allSocketsIds.includes(charger.id),
    ).length;
    allFaultedQty = chargersStatus.filter(
      (charger) =>
        (charger.status === 'FAULTED' ||
          charger.status === 'SUSPENDEDEVSE' ||
          charger.status === 'UNAVAILABLE' ||
          charger.status === 'UNKNOWN' ||
          charger.status === 'EVSE_NOT_FOUND') &&
        allSocketsIds.includes(charger.id),
    ).length;
  }

  return (
    <div className="flex flex-col items-start px-4 py-5 overflow-hidden text-center border shadow-sm h-52 w-72 bg-dash-card-bg font-custom-bold order border-stone-300 smd:w-40 rounded-2xl">
      <h1 className="font-bold text-md">Live Status'</h1>
      <div className="h-6" />
      <div className="flex flex-row items-start justify-start w-full">
        <LiveStatusItem
          chargerStatus="AVAILABLE"
          quantity={allAvailableQty}
          description="Available"
        />
        <div className="flex-1" />
        <LiveStatusItem chargerStatus="CHARGING" quantity={allChargingQty} description="Charging" />
      </div>
      <div className="h-2" />
      <div className="flex flex-row items-start justify-start w-full">
        <LiveStatusItem chargerStatus="PREPARING" quantity={allPreparingQty} description="Busy" />
        <div className="flex-1" />
        <LiveStatusItem
          chargerStatus="FAULTED"
          quantity={allFaultedQty}
          description="Offline / Fa.."
        />
      </div>
    </div>
  );
}

function LiveStatusItem({ chargerStatus, quantity, description }) {
  let statusColor = '';

  if (!chargerStatus) {
    return (
      <div className="flex flex-row my-1 items-justif">
        <div
          style={{
            width: 24,
            height: 24,
          }}
        />
        {description && (
          <p className="pl-1 text-sm truncate text-ellipsis">
            <span className="pr-1 font-bold">{quantity}</span>
            {description}
          </p>
        )}
      </div>
    );
  }

  switch (chargerStatus) {
    case 'AVAILABLE':
      statusColor = '#2F9638';
      break;
    case 'PREPARING':
    case 'SUSPENDEDEV':
    case 'FINISHING':
      statusColor = '#C28B00';
      break;
    case 'CHARGING':
      statusColor = '#0400CB';
      break;
    case 'FAULTED':
    case 'SUSPENDEDEVSE':
    case 'UNAVAILABLE':
    case 'UNKNOWN':
    case 'EVSE_NOT_FOUND':
      statusColor = '#DC362E';
      break;
    default:
      break;
  }

  return (
    <div className="flex flex-row items-start justify-start w-32 my-1">
      <div
        style={{
          width: 24,
          height: 24,
          background: statusColor,
          borderRadius: '50%',
        }}
      />
      {description && (
        <p className="pl-1 text-sm truncate ">
          <span className="pr-1 font-bold">{quantity}</span>
          {description}
        </p>
      )}
    </div>
  );
}

LiveStatusItem.propTypes = {
  chargerStatus: PropTypes.string,
  quantity: PropTypes.number,
  description: PropTypes.string,
};
