import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { IoIosInformationCircle } from 'react-icons/io';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Collapse,
  List,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedFinancialSite,
  fetchSiteInfo,
  fetchChargerSiteInfo,
  fetchSocketSiteInfo,
} from '../../store/financialSlice';
import {
  setSelectedHealthSite,
  fetchSiteUptimeInfo,
  fetchChargerUptimeData,
  fetchSocketUptimeData,
} from '../../store/healthSlice';
import { setQuarterLabel } from '../../store/uiSlice';

import { BasicSite } from '../../repositories/sites_repository/models/sites_repository_models';

import PortfolioListItem from './components/PortfolioListItem';

import ChargepointListItem from './components/ChargepointListItem';
import SocketListItem from './components/SocketListItem';

import SiteUtils from '../../repositories/sites_repository/utils/site_utils';
import { useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ChargerStatusKeyModal from './components/ChargerStatusKeyModal/ChargerStatusKeyModal';

export default function PortfolioSelect() {
  const theme = useTheme();
  const { portfolioFinancialData, selectedFinancialSite } = useSelector((state) => state.sites);
  const { portfolioHealthData, selectedHealthSite } = useSelector((state) => state.health);

  const { viewSiteDetails } = useSelector((state) => state.ui);
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const location = useLocation();

  const [selectedAll, setSelectedAll] = useState(true);
  const [allSitesFinanceBasic, setAllSitesFinanceBasic] = useState(null);
  const [allSitesHealthBasic, setAllSitesHealthBasic] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCharger, setSelectedCharger] = useState(null);
  const [selectedSocket, setSelectedSocket] = useState(null);
  const [expandedSite, setExpandedSite] = useState(null);
  const [expandedCharger, setExpandedCharger] = useState(null);
  const [showChargerStatusKey, setShowChargerStatusKey] = useState(false);

  const [selectedSite, setSelectedSite] = useState(() => {
    switch (location.pathname) {
      case '/health':
        return selectedHealthSite;
      case '/':
      case '/summary':
        return selectedFinancialSite;
      default:
        return selectedFinancialSite;
    }
  });

  useEffect(() => {
    if (selectedFinancialSite && location.pathname === '/') {
      setSelectedSite(selectedFinancialSite);
    } else if (selectedHealthSite && location.pathname === '/health') {
      setSelectedSite(selectedHealthSite);
    }
  }, [location.pathname, selectedFinancialSite, selectedHealthSite]);

  const portfolioSites = [];

  switch (location.pathname) {
    case '/health':
      portfolioSites.push(...(portfolioHealthData?.sites ?? []));
      break;
    case '/':
    case '/summary':
    case '/site-detail':
      portfolioSites.push(...(portfolioFinancialData?.sites ?? []));
      break;
    default:
  }

  const handleSelectAllSites = () => {
    dispatch(setSelectedHealthSite({ newSelectedSite: allSitesHealthBasic }));
    dispatch(setSelectedFinancialSite({ newSelectedSite: allSitesFinanceBasic }));

    setSelectedAll(true);
    setSelectedRow(null);
    setExpandedSite(null);
  };

  const handleSiteSelect = (data, index) => {
    const foundHealthSite = portfolioHealthData.sites.find((site) => site.id === data.id);
    const foundFinancialSite = portfolioFinancialData.sites.find((site) => site.id === data.id);
    const newHealthSite = new BasicSite(foundHealthSite, null, null);
    const newFinancialSite = new BasicSite(foundFinancialSite, null, null);
    dispatch(setSelectedHealthSite({ newSelectedSite: newHealthSite }));
    dispatch(setSelectedFinancialSite({ newSelectedSite: newFinancialSite }));
    if (user.pid === 'M110QfzAZ1yxPfqFqT6X') {
      dispatch(fetchSiteUptimeInfo(newHealthSite.siteInfo.id));
      dispatch(fetchSiteInfo(newFinancialSite.siteInfo.id));
    }
    setSelectedCharger(null);
    setSelectedSocket(null);
    setSelectedAll(false);
    setExpandedCharger(null);
    setSelectedRow(index);
    dispatch(setQuarterLabel('All time'));
    handleExpandSite(index);
  };

  const handleExpandSite = (siteIndex) => {
    if (viewSiteDetails) return;
    const newExpandedSite = portfolioSites[siteIndex];

    const foundHealthSite = portfolioHealthData.sites.find(
      (site) => site.id === newExpandedSite.id,
    );
    const foundFinancialSite = portfolioFinancialData.sites.find(
      (site) => site.id === newExpandedSite.id,
    );
    const newHealthSite = new BasicSite(foundHealthSite, null, null);
    const newFinancialSite = new BasicSite(foundFinancialSite, null, null);
    dispatch(setSelectedHealthSite({ newSelectedSite: newHealthSite }));
    dispatch(setSelectedFinancialSite({ newSelectedSite: newFinancialSite }));
    if (user.pid === 'M110QfzAZ1yxPfqFqT6X') {
      dispatch(fetchSiteUptimeInfo(newHealthSite.siteInfo.id));
      dispatch(fetchSiteInfo(newFinancialSite.siteInfo.id));
    }
    setExpandedSite(expandedSite === siteIndex ? null : siteIndex);
    setSelectedCharger(null);
    setExpandedCharger(null);
    setSelectedSocket(null);
    dispatch(setQuarterLabel('All time'));
  };

  const handleChargerSelect = (charger, index) => {
    const foundHealthSite = portfolioHealthData.sites.find(
      (s) => s.id === selectedSite.siteInfo.id,
    );
    const foundFinancialSite = portfolioFinancialData.sites.find(
      (s) => s.id === selectedSite.siteInfo.id,
    );
    const newFinancialSite = new BasicSite(foundFinancialSite, charger, null);
    const newHealthSite = new BasicSite(foundHealthSite, charger, null);
    dispatch(fetchChargerUptimeData(newHealthSite));
    dispatch(fetchChargerSiteInfo(newFinancialSite));
    setSelectedSocket(null);
    setSelectedCharger(charger);
    handleExpandCharger(index, false);
    dispatch(setQuarterLabel('All time'));
  };

  const handleExpandCharger = (chargerIndex, getChargerInfo) => {
    if (viewSiteDetails) return;

    if (getChargerInfo ?? true) {
      const selectedCharger = selectedSite.siteInfo.chargepoints[chargerIndex];
      const foundHealthSite = portfolioHealthData.sites.find((s) => s.id === selectedSite.id);
      const foundFinancialSite = portfolioFinancialData.sites.find((s) => s.id === selectedSite.id);

      const newFinancialSite = new BasicSite(foundFinancialSite, selectedCharger, null);
      const newHealthSite = new BasicSite(foundHealthSite, selectedCharger, null);

      dispatch(fetchChargerSiteInfo(newFinancialSite));
      dispatch(fetchChargerUptimeData(newHealthSite));
      setSelectedCharger(selectedCharger);
    }

    setExpandedCharger(expandedCharger === chargerIndex ? null : chargerIndex);
    setSelectedSocket(null);
  };

  const handleSocketSelect = (socket) => {
    const newHealthSite = new BasicSite(
      selectedHealthSite.siteInfo,
      selectedHealthSite.selectedCharger,
      socket,
    );

    const newFinancialSite = new BasicSite(
      selectedFinancialSite.siteInfo,
      selectedFinancialSite.selectedCharger,
      socket,
    );
    dispatch(fetchSocketUptimeData(newHealthSite));
    dispatch(fetchSocketSiteInfo(newFinancialSite));
    setSelectedSocket(socket);
  };

  const showChargerStatusKeyModal = () => {
    setShowChargerStatusKey(!showChargerStatusKey);
  };

  useEffect(() => {
    if (portfolioHealthData === null) return;
    const allHealthSites = portfolioHealthData.sites;
    if (allHealthSites.length === 0) return;
    const allHealthChargepoints = allHealthSites.map((site) => site.chargepoints).flat();
    const newHealthSite = SiteUtils.createAllSiteMock(portfolioHealthData, allHealthChargepoints);
    const allHealthSitesBasic = new BasicSite(newHealthSite, null, null);

    setAllSitesHealthBasic(allHealthSitesBasic);

    if (portfolioFinancialData === null) return;
    const allFinancialSites = portfolioFinancialData.sites;
    if (allFinancialSites.length === 0) return;
    const allChargepoints = allFinancialSites.map((site) => site.chargepoints).flat();
    const newFinancialSite = SiteUtils.createAllSiteMock(portfolioFinancialData, allChargepoints);
    const allFinancialSitesBasic = new BasicSite(newFinancialSite, null, null);

    setAllSitesFinanceBasic(allFinancialSitesBasic);
  }, [portfolioFinancialData, portfolioHealthData]);

  useEffect(() => {
    if (allSitesHealthBasic && allSitesHealthBasic && selectedSite === null) {
      dispatch(setSelectedHealthSite({ newSelectedSite: allSitesHealthBasic }));
      dispatch(setSelectedFinancialSite({ newSelectedSite: allSitesFinanceBasic }));
    }
  }, [allSitesFinanceBasic, allSitesHealthBasic, dispatch, selectedSite]);

  const allChargepoints = selectedSite?.siteInfo?.chargepoints ?? [];
  const allSockets = selectedSite?.siteInfo?.chargepoints?.map((e) => e.sockets).flat() ?? [];

  const socketsFromCharger =
    allSockets?.filter((e) => e.charger_name === selectedSite?.selectedCharger?.name.trim()) ?? [];
  const orderedSockets = socketsFromCharger.sort((a, b) => a.manual_code - b.manual_code);

  return (
    <div className="pl-12 pr-4 min-w-[28vw]">
      <div
        className="justify-start w-full px-5 pt-5 pb-4 bg-white border shadow-lg rounded-2xl  h-[60vh]"
        style={{ minHeight: '160px', maxHeight: 'auto', overflowY: 'auto' }}
      >
        <div className="flex flex-row items-center justify-center w-full">
          <div className="pb-2 text-lg font-customRegular">Chargers</div>
          <div className="flex-1" />
          <div
            className="flex flex-row items-center justify-center pb-2 hover:cursor-pointer "
            onClick={showChargerStatusKeyModal}
          >
            <IoIosInformationCircle size={24} color={theme.palette.btnActiveColor} />
            <div className="ml-2" />
            <p
              className="text-md"
              style={{
                color: theme.palette.btnActiveColor,
              }}
            >
              Key
            </p>
            {showChargerStatusKey && (
              <>
                <Modal
                  isOpen={showChargerStatusKey}
                  onRequestClose={showChargerStatusKeyModal}
                  shouldCloseOnEsc
                  id="charger_status_modal"
                  style={{
                    overlay: {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      zIndex: 1000,
                      position: 'absolute',
                    },
                    content: {
                      position: 'absolute',
                      paddingBottom: '0px',
                      width: '300px',
                      height: '300px',
                      top: '30%',
                      left: '25%',
                      padding: '10px',
                      borderRadius: '16px',
                      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                >
                  <ChargerStatusKeyModal closeModal={showChargerStatusKeyModal} />
                </Modal>
              </>
            )}
          </div>
        </div>
        <div className="pb-3 text-md font-customRegular">
          Showing {portfolioSites.length} locations
        </div>
        <TableContainer
          component={Paper}
          style={{
            maxHeight: 'auto',
            overflowY: 'auto',
            boxShadow: 'none',
          }}
          sx={{
            background: theme.palette.siteSelectColor,
            borderRadius: 3,
            '& .MuiTableCell-root': {
              borderBottom: 'none',
              border: 'none',
            },
          }}
        >
          <Table>
            <TableBody>
              <TableRow
                onClick={handleSelectAllSites}
                sx={selectedAll ? { backgroundColor: '#EEEEEE' } : {}}
              >
                <TableCell
                  style={{
                    padding: '12px 0px 12px 10px',
                    cursor: 'pointer',
                    fontFamily: theme.typography.secondaryFontFamily,
                  }}
                >
                  <span className="font-customRegular">All Sites</span>
                </TableCell>
              </TableRow>
              {portfolioSites?.map((site, index) => {
                return (
                  <React.Fragment key={index}>
                    <PortfolioListItem
                      isSelected={selectedRow === index}
                      site={site}
                      siteIndex={index}
                      handleSiteSelect={handleSiteSelect}
                      handleExpandSite={handleExpandSite}
                      isExpanded={expandedSite === index}
                    />

                    <Collapse key={`chargepoint_colapse_${index}`} in={expandedSite === index}>
                      <List>
                        {expandedSite === index &&
                          allChargepoints?.map((chargepoint, chargerIndex) => {
                            return (
                              <>
                                <ChargepointListItem
                                  isSelected={selectedCharger?.name === chargepoint.name}
                                  chargepoint={chargepoint}
                                  chargerIndex={chargerIndex}
                                  isExpanded={expandedCharger === chargerIndex}
                                  handleChargerSelect={handleChargerSelect}
                                  handleExpandCharger={handleExpandCharger}
                                />
                                <Collapse
                                  key={`socket_collapse_${chargerIndex}`}
                                  in={expandedCharger === chargerIndex}
                                >
                                  <List>
                                    {orderedSockets.length > 0 &&
                                      orderedSockets.map((socket, socketIndex) => (
                                        <SocketListItem
                                          isSelected={selectedSocket?.id === socket.id}
                                          handleSocketSelect={handleSocketSelect}
                                          socket={socket}
                                          socketIndex={socketIndex}
                                        />
                                      ))}
                                  </List>
                                </Collapse>
                              </>
                            );
                          })}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
