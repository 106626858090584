import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig =
  process.env.REACT_APP_ENV === 'development'
    ? {
        apiKey: 'AIzaSyApbik-ISAjsPj24t-TymTk6Xgbi_lU9_w',
        authDomain: 'dev-evc-app-4d345.firebaseapp.com',
        projectId: 'dev-evc-app-4d345',
        storageBucket: 'dev-evc-app-4d345.appspot.com',
        messagingSenderId: '1085369160176',
        appId: '1:1085369160176:web:5d76b6924803cd01c1cd35',
        measurementId: 'G-6N5S0ML6DN',
      }
    : {
        apiKey: 'AIzaSyAeH1y8G6AJiBeJ2J2rXtP8n_0YNfVEUoM',
        authDomain: 'prod-evc-app.firebaseapp.com',
        projectId: 'prod-evc-app',
        storageBucket: 'prod-evc-app.appspot.com',
        messagingSenderId: '887555542671',
        appId: '1:887555542671:web:e6d8932b12a0cf54766afa',
        measurementId: 'G-EE753L9426',
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export const db = getFirestore(app);

export default auth;
