import { useSelector } from 'react-redux';
import { signOut } from 'firebase/auth';
import auth from '../../firebase';
import { Button } from '@mui/material';
import Modal from 'react-modal';
import { IoIosClose } from 'react-icons/io';
import { ReactComponent as EvcIcon } from '../../images/evcLogoWhite.svg';
import { ReactComponent as NcpIcon } from '../../images/NCPLOGO.svg';

const PendingAccount = () => {
  const { themeSelect } = useSelector((state) => state.user);

  const selectedTheme = themeSelect === 'NCP' ? <NcpIcon /> : <EvcIcon />;

  return (
    <div className="flex w-full h-full" >
      <div className="w-[80px] bg-side-bar-color">
        <div className="pt-16 ml-3">{selectedTheme}</div>
      </div>
      <div className="p-12 w-full h-full overflow-hidden">
        <div className='w-[300px] h-[60px] bg-gray-300 mb-4' ></div>
        <div className='w-[550px] h-[30px] bg-gray-300 mb-6'></div>
        <div className='w-[350px] h-[70px] bg-gray-300 mb-4' ></div>
        <div className='flex h-full w-full overflow-hidden'>
          <div className='w-[380px] h-full  bg-gray-300 mr-4' ></div>
          <div className='w-full h-full  bg-gray-300' ></div>
        </div>
      </div>

      <Modal
        isOpen={true}
        id="pending_status_modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(79, 79, 79, 0.8)',
            zIndex: 1000,
            position: 'absolute',
          },
          content: {
            position: 'absolute',
            paddingBottom: '0px',
            width: '400px',
            height: '270px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '24px',
            borderRadius: '25px',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)',
          },
        }}
      >
        <div className='flex justify-between'>
          <h1 className='w-[300px] text-[24px] font-medium mb-4'>Your registration has been submitted</h1>
          <IoIosClose onClick={() => signOut(auth)} className="hover:cursor-pointer" size={36} color='#24712A' />
        </div>
        <p className='text-[14px] mb-4'>
          Due to the sensitivity of charge point data we require an approval for all account registrations. A member of the EVC team has received your request and will update your account status.
        </p>
        <Button
          sx={{ color: '#24712A', right: '24px', position: 'absolute'}}
          variant="text"
          onClick={() => {
            signOut(auth);
          }}>
          log out
        </Button>
      </Modal>
    </div>
  )
}

export default PendingAccount;