import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ChargersRepository } from '../repositories/chargers_repository/chargers_repository';

export const fetchChargersStatus = createAsyncThunk('chargers/status', async (_, { getState }) => {
  try {
    const userState = getState().user;
    const token = userState.user.token;

    const portfolioId = userState.user.pid;
    const siteId = userState.user.sid;
    if (portfolioId) {
      const chargersStatus = await ChargersRepository.getPortfolioChargersStatus(
        portfolioId,
        token,
      );
      return chargersStatus;
    } else if (siteId) {
      const chargersStatus = await ChargersRepository.getSiteChargersStatus(siteId, token);
      return chargersStatus;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching chargers status:', error);
  }
});

const initialState = {
  chargersStatus: [],
  isLoading: false,
  error: null,
};

const chargersSlice = createSlice({
  name: 'chargers',
  initialState,
  reducers: {
    setChargerIdsForSite: (state, action) => {
      state.siteChargerIds = action.payload;
    },
    setInitialState: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChargersStatus.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchChargersStatus.fulfilled, (state, action) => {
      state.chargersStatus = action?.payload ?? [];
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(fetchChargersStatus.rejected, (state, action) => {
      console.log(action.error.message ?? 'Something went wrong.');
      state.chargersStatus = [];
      state.isLoading = false;
      state.error = action.error.message ?? 'Something went wrong.';
    });
  },
});

export const { siteChargerIds, setChargerIdsForSite, setChargerSelect, setSocketSelect } =
  chargersSlice.actions;
export default chargersSlice.reducer;
