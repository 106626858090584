import React from 'react';
import { TextField } from '@mui/material';

interface PortfolioInfoProps {
  portfolioName: string;
  portfolioModel: string;
  portfolioType: string;
}

export default function PortfolioInfo({
  portfolioName,
  portfolioModel,
  portfolioType,
}: PortfolioInfoProps) {
  return (
    <>
      <TextField
        label="Portfolio"
        id="outlined-size-small"
        value={portfolioName}
        fullWidth
        margin="dense"
        InputProps={{
          readOnly: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
      />
      <div className="sm:flex">
        <div className="w-full mr-2 sm:w1/2">
          <TextField
            label="Model"
            id="outlined-size-small"
            value={portfolioModel}
            margin="dense"
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            fullWidth
          />
        </div>
        <div className="w-full sm:w1/2">
          <TextField
            label="Site Type"
            id="outlined-size-small"
            value={portfolioType}
            margin="dense"
            size="small"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
    </>
  );
}
