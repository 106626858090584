import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { DateRangePicker } from '../common/DateRangePicker';
import DashboardCard from '../Summary/components/DashboardCard';
import HealthIssuesTypesCard from './components/HealthIssuesTypesCard';
import HealthUptimeCard from './components/HealthUptimeCard';

import {
  setCustomDateFrom,
  setCustomDateTo,
  setAvailableDataMinDate,
  setAvailableDataMaxDate,
  setQuarterLabel,
} from '../../store/uiSlice';

import { setHealthSiteByCustomDate } from '../../store/healthSlice';
import { Loader } from '../common/Loader';
import { useLocation } from 'react-router-dom';
import { createEmptyHealthQuarter } from '../../utils/helper';
import { setFinancialSiteByCustomDate } from '../../store/financialSlice';
import QuarterSelector from '../common/QuarterSelector';

export default function Health() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { customDateSelected, selectedTimePeriod, customDateFrom, customDateTo } = useSelector(
    (state) => state.ui,
  );

  const { allAvailableHealthQuarters, selectedHealthSite, customHealthQToShow } = useSelector(
    (state) => state.health,
  );
  const { selectedFinancialSite } = useSelector((state) => state.sites);

  const [qToShow, setQToShow] = useState(() => {
    return selectedHealthSite?.siteInfo.quarters[0];
  });

  useEffect(() => {
    if (selectedHealthSite && selectedTimePeriod && !customDateSelected) {
      const selectedSiteAvailableQuarters = selectedHealthSite.siteInfo.quarters.map(
        (q) => q.label,
      );
      const quarterMatchingSelectedTimePeriod = selectedHealthSite.siteInfo.quarters.find(
        (q) => q.label === selectedTimePeriod.label,
      );

      //if selected site doesn't have data for selected quarter
      if (selectedSiteAvailableQuarters.includes(selectedTimePeriod.label)) {
        setQToShow(quarterMatchingSelectedTimePeriod);
        dispatch(setAvailableDataMinDate(quarterMatchingSelectedTimePeriod.start));
        dispatch(setAvailableDataMaxDate(quarterMatchingSelectedTimePeriod.end));
        dispatch(setCustomDateFrom(null));
        dispatch(setCustomDateTo(null));
      } else {
        setQToShow(
          createEmptyHealthQuarter(
            selectedTimePeriod.label,
            selectedTimePeriod.start,
            selectedTimePeriod.end,
          ),
        );
        dispatch(setAvailableDataMinDate(selectedHealthSite?.siteInfo.quarters[0].start));
        dispatch(setAvailableDataMaxDate(selectedHealthSite?.siteInfo.quarters[0].end));
        dispatch(setCustomDateFrom(null));
        dispatch(setCustomDateTo(null));
      }
    }
  }, [selectedHealthSite, selectedTimePeriod, location.pathname, customDateSelected, dispatch]);

  useEffect(() => {
    if (customDateTo && customDateFrom && customDateSelected) {
      const customLabel = `${moment(customDateFrom).format('DD-MM-YYYY')} ${moment(
        customDateTo,
      ).format('DD-MM-YYYY')}`;

      dispatch(setQuarterLabel(customLabel));
      dispatch(setFinancialSiteByCustomDate({ customDateFrom, customDateTo }));
      dispatch(
        setHealthSiteByCustomDate({
          healthCustomDateFrom: customDateFrom,
          healthCustomDateTo: customDateTo,
        }),
      );
    }
  }, [customDateSelected, customDateFrom, customDateTo, selectedHealthSite, dispatch]);

  useEffect(() => {
    if (customDateSelected && customHealthQToShow) {
      setQToShow(customHealthQToShow);
    }
  }, [customDateSelected, customHealthQToShow]);

  const setDurationString = (durationInSecs) => {
    const duration = moment.duration(durationInSecs, 'seconds');
    if (durationInSecs === 0) {
      return '00:00:00';
    }
    return `${String(duration.hours()).padStart(2, '0')}:${String(duration.minutes()).padStart(
      2,
      '0',
    )}:${String(duration.seconds()).padStart(2, '0')}`;
  };

  if (!selectedHealthSite || !selectedFinancialSite || !qToShow || !qToShow.uptimeData) {
    return (
      <div className="flex flex-col items-start min-w-[930px] h-screen w-[65vw]  ">
        <div className="flex flex-row items-center justify-start w-full pl-4 border shadow-sm rounded-2xl bg-dash-card-bg">
          <div className="flex items-center justify-start h-16 pt-2 pb-1 pl-4 text-2xl font-semibold bg-dash-panel-bg font-custom-bold">
            Health
          </div>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start min-w-[930px] w-[65vw] overflow-y-scroll">
      <div className="flex flex-row items-center justify-start w-full pl-4 border shadow-sm rounded-2xl bg-dash-card-bg">
        <div className="flex items-center justify-start h-16 pt-2 pb-1 pl-4 text-2xl font-semibold bg-dash-panel-bg font-custom-bold">
          Health
        </div>
        <div className="flex-1" />
        <div className="flex flex-row items-center justify-end w-full">
          {customDateSelected ? (
            <div className="flex justify-end mr-0">
              <div className="flex items-center justify-end h-16 pt-2 pr-4">
                <DateRangePicker label="Start date" isStart />
                <span className="px-2">-</span>
                <DateRangePicker label="End date" />
              </div>
            </div>
          ) : (
            <span className="justify-end pr-2 text-lg font-customRegular">
              {`${moment(qToShow.start).format('Do MMM YYYY')} - ${moment(qToShow.end).format(
                'Do MMM YYYY',
              )}`}
            </span>
          )}
          <div className="w-4" />
          <QuarterSelector
            quartersToSelect={allAvailableHealthQuarters}
            isCustomDateSelected={customDateSelected}
            defaultValue={selectedHealthSite.siteInfo.quarters[0]}
            qToShow={qToShow}
          />
        </div>
      </div>
      <div className="flex flex-row w-full space-x-[20px]  mt-[20px] ">
        <HealthUptimeCard qToShow={qToShow} />
        <div className="flex flex-col items-start justify-start w-full ">
          <HealthIssuesTypesCard qToShow={qToShow} />
          <div className="h-[20px]" />
          <div className="flex flex-col w-full">
            <DashboardCard
              title="Average Unavailable Duration"
              value={setDurationString(qToShow.uptimeData.unavailableAvg)}
              showDurationKey
            />
          </div>
          <div className="h-[20px]" />
          <div className="flex flex-col w-full">
            <DashboardCard
              title="Average Fault Duration"
              value={setDurationString(qToShow.uptimeData.faultedAvg)}
              showDurationKey
            />
          </div>
        </div>
      </div>
    </div>
  );
}
