import React, { useState, useEffect } from 'react';
import { Container, TextField, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { Loader } from '../common/Loader';

import PortfolioInfo from './components/PortfolioInfo';
import { useLocation } from 'react-router-dom';

const SitePortalCard = () => {
  const theme = useTheme();
  const location = useLocation();
  const { selectedFinancialSite, portfolioFinancialData, siteData } = useSelector(
    (state) => state.sites,
  );

  const { selectedHealthSite, portfolioHealthData } = useSelector((state) => state.health);

  const [selectedSite, setSelectedSite] = useState(() => {
    switch (location.pathname) {
      case '/health':
        return selectedHealthSite;
      case '/':
      case '/summary':
      case '/site-detail':
        return selectedFinancialSite;
      default:
        return selectedFinancialSite;
    }
  });

  const [selectedPortfolioData, setSelectedPortfolioData] = useState(() => {
    switch (location.pathname) {
      case '/health':
        return portfolioHealthData;
      case '/':
      case '/summary':
      case '/site-detail':
        return portfolioFinancialData;
      default:
        return portfolioFinancialData;
    }
  });

  useEffect(() => {
    if (
      selectedFinancialSite &&
      (location.pathname === '/' || location.pathname === '/site-detail')
    ) {
      setSelectedSite(selectedFinancialSite);
    } else if (selectedHealthSite && location.pathname === '/health') {
      setSelectedSite(selectedHealthSite);
    }
  }, [location.pathname, selectedFinancialSite, selectedHealthSite]);

  useEffect(() => {
    if (
      portfolioFinancialData &&
      (location.pathname === '/' || location.pathname === '/site-detail')
    ) {
      setSelectedPortfolioData(portfolioFinancialData);
    } else if (portfolioHealthData && location.pathname === '/health') {
      setSelectedPortfolioData(portfolioHealthData);
    }
  }, [location.pathname, portfolioFinancialData, portfolioHealthData]);

  if ((!selectedPortfolioData && !siteData) || !selectedSite) {
    return <Loader />; // Render nothing or a loading indicator while data is loading
  }

  return (
    <Container>
      <div className="w-full p-3 overflow-hidden border shadow-sm border-stone-300 rounded-2xl">
        {selectedPortfolioData && (
          <PortfolioInfo
            portfolioName={selectedPortfolioData.description}
            portfolioModel={selectedSite.siteInfo.model}
            portfolioType={selectedSite.siteInfo.siteType}
          />
        )}
        {selectedSite.siteInfo.id !== 'All Sites' ? (
          <>
            <div>
              {selectedSite.siteInfo?.clientContactPerson && (
                <TextField
                  label="Key Contact"
                  id="outlined-size-small"
                  value={selectedSite.siteInfo?.clientContactPerson}
                  fullWidth
                  margin="dense"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                />
              )}
              <div className="sm:flex">
                <div className="w-full mr-2 sm:w1/2">
                  {selectedSite.siteInfo?.clientContactEmail && (
                    <TextField
                      label="Email"
                      id="outlined-size-small"
                      value={selectedSite.siteInfo?.clientContactEmail}
                      fullWidth
                      margin="dense"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                    />
                  )}
                </div>
                <div className="w-full sm:w1/2">
                  {selectedSite.siteInfo?.clientContactNo && (
                    <TextField
                      label="Contact No"
                      id="outlined-size-small"
                      value={selectedSite.siteInfo?.clientContactNo}
                      fullWidth
                      margin="dense"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="sm:flex">
              <div className="w-full mr-2 sm:w1/2">
                {selectedSite.siteInfo?.siteAddress1 && (
                  <TextField
                    label="Address Line 1"
                    id="outlined-size-small"
                    value={selectedSite.siteInfo?.siteAddress1}
                    margin="dense"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    fullWidth
                  />
                )}
              </div>
              <div className="w-full sm:w1/2">
                {selectedSite.siteInfo?.siteAddress2 && (
                  <TextField
                    label="Address Line 2"
                    id="outlined-size-small"
                    value={selectedSite.siteInfo?.siteAddress2}
                    margin="dense"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    fullWidth
                  />
                )}
              </div>
            </div>

            <div className="sm:flex">
              <div className="w-full mr-2 sm:w1/2">
                {selectedSite.siteInfo?.siteCounty && (
                  <TextField
                    label="County"
                    id="outlined-size-small"
                    value={selectedSite.siteInfo?.siteCounty}
                    margin="dense"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    fullWidth
                  />
                )}
              </div>
              <div className="w-full sm:w1/2">
                {selectedSite.siteInfo?.sitePostcode && (
                  <TextField
                    label="Postcode"
                    id="outlined-size-small"
                    value={selectedSite.siteInfo?.sitePostcode}
                    margin="dense"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    fullWidth
                  />
                )}
              </div>
            </div>

            <div className="sm:flex">
              <div className="w-full mr-2 sm:w1/2">
                {selectedSite.siteInfo?.model && (
                  <TextField
                    label="Model"
                    id="outlined-size-small"
                    sx={{ fontFamily: theme.typography.secondaryFontFamily }}
                    value={selectedSite.siteInfo?.model}
                    margin="dense"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    fullWidth
                  />
                )}
              </div>
              <div className="w-full sm:w1/2">
                {selectedSite.siteInfo?.siteType && (
                  <TextField
                    label="Site Type"
                    id="outlined-size-small"
                    value={selectedSite.siteInfo?.siteType}
                    margin="dense"
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              </div>
            </div>

            <div className="sm:flex">
              <div className="w-full mr-2 sm:w1/2">
                {selectedSite.siteInfo?.timestamp && (
                  <TextField
                    label="Added Date"
                    id="outlined-size-small"
                    value={new Date(selectedSite.siteInfo?.timestamp).toLocaleDateString()}
                    margin="dense"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    fullWidth
                  />
                )}
              </div>
              <div className="w-full sm:w1/2">
                {selectedSite.siteInfo?.eConnection && (
                  <TextField
                    label="Power Connection"
                    id="outlined-size-small"
                    value={selectedSite.siteInfo?.eConnection}
                    margin="dense"
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    fullWidth
                  />
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Container>
  );
};

export default SitePortalCard;
