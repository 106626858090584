import React from 'react';
import Select from 'react-select';
import { useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { customizeDate, setQuarterLabel, setSelectedTimePeriod } from '../../store/uiSlice';

import PropTypes from 'prop-types';

export default function QuarterSelector({
  quartersToSelect,
  defaultValue,
  isCustomDateSelected,
  qToShow,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Select
      className="mr-8 w-1/8 font-customRegular"
      styles={{
        control: (provided) => ({
          ...provided,
          border: 'none',
          fontWeight: 'bold',
          boxShadow: 'none',
          '&:hover': {
            border: 'none',
          },
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: theme.palette.dropdownColor,
        }),
      }}
      options={[
        ...quartersToSelect,
        {
          id: 'qCustom',
          value: 'custom',
          label: 'Custom',
        },
      ]}
      defaultValue={defaultValue}
      value={
        isCustomDateSelected
          ? {
              id: 'qCustom',
              value: 'custom',
              label: 'Custom',
            }
          : {
              id: qToShow.start + qToShow.end,
              value: qToShow,
              label: qToShow.label,
            }
      }
      onChange={(e) => {
        if (e.id === 'qCustom') {
          dispatch(customizeDate(true));
          dispatch(setSelectedTimePeriod(e));
        } else {
          dispatch(customizeDate(false));
          dispatch(setQuarterLabel(e.label));
          dispatch(setSelectedTimePeriod(e));
        }
      }}
    />
  );
}

QuarterSelector.propTypes = {
  quartersToSelect: PropTypes.array,
  defaultValue: PropTypes.object,
  isCustomDateSelected: PropTypes.bool,
  qToShow: PropTypes.object,
};
