/* eslint-disable react/prop-types */
import React from 'react';
import { Button, TableCell, TableRow } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';

const PortfolioListItem = ({
  isSelected,
  isExpanded,
  site,
  siteIndex,
  handleExpandSite,
  handleSiteSelect,
}) => {
  const theme = useTheme();

  return (
    <TableRow
      style={{ padding: '5px 0px 5px 8px', cursor: 'pointer' }}
      selected={isSelected}
      onClick={() => {
        handleSiteSelect(site, siteIndex);
      }}
      sx={isSelected ? { backgroundColor: '#EEEEEE !important' } : {}}
    >
      <TableCell style={{ padding: '5px 0px 5px 8px' }}>
        <div
          style={{
            minWidth: '268px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: theme.typography.secondaryFontFamily,
          }}
        >
          {site.siteName}

          <Button style={{ cursor: 'pointer' }} onClick={() => handleExpandSite(siteIndex)}>
            {isExpanded ? (
              <ExpandLessIcon style={{ color: theme.palette.dropdownColor }} />
            ) : (
              <ExpandMoreIcon style={{ color: theme.palette.dropdownColor }} />
            )}
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

PortfolioListItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  site: PropTypes.isRequired,
  siteIndex: PropTypes.number.isRequired,
  handleSiteSelect: PropTypes.func.isRequired,
  handleExpandSite: PropTypes.func.isRequired,
};

export default PortfolioListItem;
