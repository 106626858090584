/* eslint-disable react/prop-types */
import React from 'react';
import { Button, ListItem, ListItemText, Typography } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import StatusKeyItem from './ChargerStatusKeyModal/StatusKeyItem';

const ChargepointListItem = ({
  chargerIndex,
  chargepoint,
  isSelected,
  isExpanded,
  handleChargerSelect,
  handleExpandCharger,
}) => {
  const theme = useTheme();
  const { chargersStatus } = useSelector((state) => state.chargers);

  const socketStatuses = chargepoint.sockets.map((socket) => {
    const charger = chargersStatus.find((charger) => charger.id === socket.id);
    return charger ? charger.status : 'Unknown';
  });

  const allAvailable = socketStatuses.every((status) => status === 'AVAILABLE');

  const hasFaultedSockets = socketStatuses.some(
    (status) =>
      status === 'FAULTED' ||
      status === 'UNKNOWN' ||
      status === 'UNAVAILABLE' ||
      status === 'SUSPENDEDEVSE' ||
      status === 'EVSE_NOT_FOUND',
  );

  const hasChargingSockets = socketStatuses.some((status) => status === 'CHARGING');

  const hasPreparingSockets = socketStatuses.some(
    (status) => status === 'PREPARING' || status === 'FINISHING' || status === 'SUSPENDEDEV',
  );

  let statusToUse = '';

  if (hasFaultedSockets) {
    statusToUse = 'FAULTED';
  } else if (hasChargingSockets) {
    statusToUse = 'CHARGING';
  } else if (hasPreparingSockets) {
    statusToUse = 'PREPARING';
  } else if (allAvailable) {
    statusToUse = 'AVAILABLE';
  }

  return (
    <ListItem
      key={`${chargerIndex}-${chargepoint.name}`}
      className="inline-flex "
      sx={isSelected ? { backgroundColor: '#EEEEEE !important' } : {}}
    >
      <ListItemText
        tabIndex={chargerIndex}
        primary={
          <div className="flex justify-start w-full ">
            <div
              className="flex flex-row items-center w-3/4 "
              onClick={() => handleChargerSelect(chargepoint, chargerIndex)}
            >
              <StatusKeyItem chargerStatus={statusToUse} />
              <div className="w-4" />
              <Typography
                className=""
                variant="body2"
                style={{
                  cursor: 'pointer',
                  fontFamily: theme.typography.secondaryFontFamily,
                }}
              >
                {chargepoint.name}
              </Typography>
            </div>
            <div className="w-1/4">
              <Button
                style={{ cursor: 'pointer' }}
                onClick={() => handleExpandCharger(chargerIndex)}
              >
                {isExpanded ? (
                  <ExpandLessIcon style={{ color: theme.palette.dropdownColor }} />
                ) : (
                  <ExpandMoreIcon style={{ color: theme.palette.dropdownColor }} />
                )}
              </Button>
            </div>
          </div>
        }
      />
    </ListItem>
  );
};

ChargepointListItem.propTypes = {
  chargerIndex: PropTypes.number.isRequired,
  chargepoint: PropTypes.any.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  handleChargerSelect: PropTypes.func.isRequired,
  handleExpandCharger: PropTypes.func.isRequired,
};

export default ChargepointListItem;
