import axios from 'axios';

import moment from 'moment';

import { BasicSite } from '../sites_repository/models/sites_repository_models';
import {
  BasicHealthSite,
  HealthQuarter,
  HealthSite,
  PortfolioHealthSites,
} from './models/health_repository_models';
import { ChargePoint } from '../models/models';
import SiteUtils from '../sites_repository/utils/site_utils';


const prodBaseUrl = 'https://europe-west2-prod-evc-app.cloudfunctions.net';
const devBaseUrl = 'https://europe-west2-dev-evc-app-4d345.cloudfunctions.net'
const baseUrl = process.env.REACT_APP_ENV === 'development' ? devBaseUrl : prodBaseUrl


export abstract class HealthRepository {
  static async getPortfolioUptimeData(pid: string, token: string): Promise<PortfolioHealthSites> {
    try {
      const response = await axios.get(
        `${baseUrl}/evc_data/client_portfolio_sites_uptime_data?pid=${pid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data as PortfolioHealthSites;
    } catch (error) {
      console.log(error);
      throw Error('There was an error getting the portfolio data');
    }
  }
  static async getSiteUptimeData(sid: string, token: string): Promise<any> {
    try {
      const response = await axios.get(
        `${baseUrl}/evc_data/client_portfolio_sites_uptime_data?sid=${sid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data as any;
    } catch (error) {
      console.log(error);
      throw Error('There was an error getting the portfolio data');
    }
  }

  static async getChargepointUptimeData(
    siteData: BasicSite,
    token: string,
  ): Promise<BasicHealthSite> {
    const chargerSocketsIds = siteData.selectedCharger?.sockets.map((socket) => socket.id);
    const totalChargepoints = siteData.siteInfo.chargepoints;
    const uniqueChargepoints = [];

    if (!chargerSocketsIds) {
      throw Error('No charger sockets found');
    }
    let startUrl = `${baseUrl}/evc_data/client_charger_socket_uptime_data?sid=${siteData.siteInfo.id}`;
    for (let i = 0; i < chargerSocketsIds.length; i++) {
      const id = chargerSocketsIds[i];

      startUrl += `&chargerId${i + 1}=${id}`;
    }

    const response = await axios.get(startUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const newSite = response.data as HealthSite;
    const newChargepoint = newSite.chargepoints[0];
    const repeatedChargerTxIndex = totalChargepoints.findIndex(
      (e) => e.name === newChargepoint.name,
    );

    for (let i = 0; i < totalChargepoints.length; i++) {
      if (repeatedChargerTxIndex === i) {
        uniqueChargepoints.push(newChargepoint);
      } else {
        uniqueChargepoints.push(totalChargepoints[i]);
      }
    }

    return setNewMergedSite(newSite, siteData, uniqueChargepoints);
  }

  static async getSocketUptimeData(siteData: BasicSite, token: string): Promise<BasicHealthSite> {
    const totalChargepoints = siteData.siteInfo.chargepoints;
    const uniqueChargepoints = [];
    const url = `${baseUrl}/evc_data/client_charger_socket_uptime_data?sid=${siteData.siteInfo.id}&chargerId1=${siteData.selectedSocket?.id}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const newSite = response.data as HealthSite;
    const updatedChargepoint = newSite.chargepoints[0];
    updatedChargepoint.sockets.forEach((s) => {
      s.manualCode = s.manual_code ?? s.manualCode;
    });

    const repeatedChargepointIndex = totalChargepoints.findIndex(
      (e) => e.name === updatedChargepoint.name,
    );

    const nonUpdatedSocket = siteData.selectedCharger?.sockets.find(
      (s) => s.id !== siteData.selectedSocket?.id,
    );

    updatedChargepoint.sockets.push(nonUpdatedSocket!);

    const orderedSockets = updatedChargepoint.sockets.sort((a, b) => {
      if (a.manual_code && b.manual_code) {
        return a.manual_code.localeCompare(b.manual_code);
      }
      return a.manualCode.localeCompare(b.manualCode);
    });
    updatedChargepoint.sockets = orderedSockets;

    for (let i = 0; i < totalChargepoints.length; i++) {
      if (repeatedChargepointIndex === i) {
        uniqueChargepoints.push(updatedChargepoint);
      } else {
        uniqueChargepoints.push(totalChargepoints[i]);
      }
    }

    return setNewMergedSite(newSite, siteData, uniqueChargepoints);
  }

  static getSelectedPortfolioWithCustomDate(
    siteData: BasicHealthSite,
    dateFrom: Date,
    dateTo: Date,
  ): HealthQuarter {
    const totalDays = moment(dateTo).diff(moment(dateFrom), 'days') + 1;
    const id = `qCustom-${dateFrom.toISOString()}_${dateTo.toISOString()}`;
    const allUptimes = siteData.siteInfo.quarters[0].uptimeData.uptimes;
    const selectedChargerSocketsIds = siteData?.selectedCharger?.sockets.map((e) => e.id) ?? [];
    const selectedSocketId = siteData?.selectedSocket?.id ?? '';

    const uptimeData = SiteUtils.calculateCustomUptimeData(
      allUptimes,
      selectedChargerSocketsIds,
      selectedSocketId,
      dateFrom,
      dateTo,
      totalDays,
    );

    return {
      id: id,
      start: moment(dateFrom),
      end: moment(dateTo),
      label: `Custom: ${moment(dateFrom).format('DD/MM/YYYY')} - ${moment(dateTo).format(
        'DD/MM/YYYY',
      )}`,
      totalDays: totalDays,
      uptimeData: uptimeData,
    } as HealthQuarter;
  }
}

/// This builds a new BasicSite instance, used for when user selects a new charger or socket
const setNewMergedSite = (
  newSite: HealthSite,
  oldSiteData: BasicSite,
  uniqueChargepoints: Array<ChargePoint>,
) => {
  const workedMergedSite = {
    siteName: newSite.siteName,
    geo: newSite.geo,
    siteAddress1: newSite.siteAddress1,
    sitePostcode: newSite.sitePostcode,
    isPublic: newSite.isPublic,
    client: newSite.client,
    model: newSite.model,
    siteCrm: newSite.siteCrm,
    siteType: newSite.siteType,
    timestamp: newSite.timestamp,
    clientContactPerson: newSite.clientContactPerson,
    clientContactNo: newSite.clientContactNo,
    clientContactEmail: newSite.clientContactEmail,
    siteAddress2: newSite.siteAddress2,
    siteCounty: newSite.siteCounty,
    baysAtLocation: newSite.baysAtLocation,
    chargerPowerRates: newSite.chargerPowerRates,
    chargerSpeed: newSite.chargerSpeed,
    quarters: newSite.quarters,
    hasAvailableCharger: newSite.hasAvailableCharger,
    chargepoints: uniqueChargepoints,
    chargerPaymentMethods: newSite.chargerPaymentMethods,
    chargerConnectionType: newSite.chargerConnectionType,
    eConnection: newSite.eConnection,
    isDiscoverable: newSite.isDiscoverable,
    updatedTimestamp: newSite.updatedTimestamp,
    id: newSite.id,
    allSitesGeo: newSite.allSitesGeo,
    allSitesNames: newSite.allSitesNames,
    displayUptime: newSite.displayUptime,
  } as HealthSite;

  return new BasicHealthSite(
    workedMergedSite,
    oldSiteData.selectedCharger,
    oldSiteData.selectedSocket,
  );
};
