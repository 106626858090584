import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { HealthRepository } from '../repositories/health_repository/health_repository';
import { BasicHealthSite } from '../repositories/health_repository/models/health_repository_models';

export const fetchInitialUptimeData = createAsyncThunk('health/fetch', async (_, { getState }) => {
  try {
    const userState = getState().user;

    const portfolioId = userState.user.pid;
    const siteId = userState.user.sid;
    let initialPortfolioData = null;
    let initialSiteData = null;
    let newPortfolioData = null;

    if (portfolioId) {
      initialPortfolioData = await HealthRepository.getPortfolioUptimeData(
        portfolioId,
        userState.user.token,
      );

      newPortfolioData = {
        end: initialPortfolioData.portfolioData.end,
        start: initialPortfolioData.portfolioData.start,
        label: initialPortfolioData.portfolioData.label,
        totalDays: initialPortfolioData.portfolioData.totalDays,
        quarters: initialPortfolioData.portfolioData.quarters,
        sites: initialPortfolioData.sites,
        displayUptime: initialPortfolioData.portfolioData.displayUptime,
      };
      newPortfolioData.sites = newPortfolioData.sites.map((site) => {
        site.displayUptime = newPortfolioData.displayUptime;
        return site;
      });
    } else {
      initialSiteData = await HealthRepository.getSiteUptimeData(siteId, userState.user.token);
    }

    return {
      portfolioHealthData: newPortfolioData,
      siteHealthData: initialSiteData,
    };
  } catch (error) {
    console.error('Error fetching site information:', error);
  }
});

export const fetchSiteUptimeInfo = createAsyncThunk(
  'health/site/fetch',
  async (siteId, { getState }) => {
    try {
      const userState = getState().user;
      const token = userState.user.token;
      return await HealthRepository.getSiteUptimeData(siteId, token);
    } catch (error) {
      console.error('Error fetching site information:', error);
    }
  },
);

export const fetchChargerUptimeData = createAsyncThunk(
  'health/charger/fetch',
  async (newSelectedSite, { getState }) => {
    try {
      const userState = getState().user;
      const token = userState.user.token;

      const siteWithChargerData = await HealthRepository.getChargepointUptimeData(
        newSelectedSite,
        token,
      );

      return {
        siteWithChargerData,
      };
    } catch (error) {
      console.error('Error fetching site information:', error);
    }
  },
);

export const fetchSocketUptimeData = createAsyncThunk(
  'health/socket/fetch',
  async (newSelectedSite, { getState }) => {
    try {
      const userState = getState().user;
      const token = userState.user.token;

      const siteWithSocketData = await HealthRepository.getSocketUptimeData(newSelectedSite, token);

      return {
        siteWithSocketData,
      };
    } catch (error) {
      console.error('Error fetching site information:', error);
    }
  },
);

const initialState = {
  portfolioHealthData: null,
  siteHealthData: null,
  customHealthQToShow: null,
  selectedHealthSite: null,
  isLoadingHealth: false,
  error: null,
  allAvailableHealthQuarters: null,
};

const healthSlice = createSlice({
  name: 'health',
  initialState,
  reducers: {
    setSelectedHealthSite: (state, action) => {
      const { newSelectedSite } = action.payload;

      state.selectedHealthSite = newSelectedSite;
    },
    setAllChargerIds: (state, action) => {
      state.allChargerIds = action.payload;
    },
    setHealthSiteByCustomDate: (state, action) => {
      const selectedHealthSite = current(state).selectedHealthSite;
      const { healthCustomDateFrom, healthCustomDateTo } = action.payload;
      let newQToShow = null;

      if (selectedHealthSite.siteInfo) {
        newQToShow = HealthRepository.getSelectedPortfolioWithCustomDate(
          selectedHealthSite,
          healthCustomDateFrom,
          healthCustomDateTo,
        );
      }
      state.customHealthQToShow = newQToShow;
    },
    clearHealthCustomQuarter: (state) => {
      state.customHealthQToShow = null;
    },
    clearHealthSites: (state) => {
      state.portfolioHealthData = null;
      state.siteHealthData = null;
      state.selectedHealthSite = null;
      state.isLoadingHealth = false;
      state.error = null;
      state.customHealthQToShow = null;
      state.allAvailableHealthQuarters = null;
    },
  },
  extraReducers: (builder) => {
    /// All Sites Info
    builder.addCase(fetchInitialUptimeData.pending, (state) => {
      state.isLoadingHealth = true;
      state.error = null;
    });
    builder.addCase(fetchInitialUptimeData.fulfilled, (state, action) => {
      const portfolioHealthData = action.payload.portfolioHealthData;
      const siteHealthData = action.payload.siteHealthData;
      let allAvailableQuarters = [];
      if (siteHealthData) {
        const newSelectedSite = new BasicHealthSite(siteHealthData);
        state.selectedHealthSite = newSelectedSite;
        allAvailableQuarters = siteHealthData.quarters.map((q) => ({
          id: q.id,
          label: q.label,
          start: q.start,
          end: q.end,
        }));
      } else if (portfolioHealthData) {
        allAvailableQuarters = portfolioHealthData.quarters.map((q) => ({
          id: q.id,
          label: q.label,
          start: q.start,
          end: q.end,
        }));
      }

      state.allAvailableHealthQuarters = allAvailableQuarters;
      state.portfolioHealthData = portfolioHealthData;
      state.siteHealthData = siteHealthData ?? null;
      state.isLoadingHealth = false;
      state.error = null;
    });
    builder.addCase(fetchInitialUptimeData.rejected, (state, action) => {
      state.isLoadingHealth = true;
      state.error = action.error.message ?? 'Something went wrong.';
    });
    // Single Site Info
    builder.addCase(fetchSiteUptimeInfo.pending, (state) => {
      state.isLoadingHealth = true;
      state.error = null;
    });
    builder.addCase(fetchSiteUptimeInfo.fulfilled, (state, action) => {
      const siteHealthData = action.payload;
      const newSelectedSite = new BasicHealthSite(siteHealthData);
      state.selectedHealthSite = newSelectedSite;
      state.allAvailableHealthQuarters = siteHealthData.quarters.map((q) => ({
        id: q.id,
        label: q.label,
        start: q.start,
        end: q.end,
      }));
      state.isLoadingHealth = false;
      state.error = null;
    });
    builder.addCase(fetchSiteUptimeInfo.rejected, (state, action) => {
      state.isLoadingHealth = true;
      state.error = action.error.message ?? 'Something went wrong.';
    });

    /// Charger Site Info
    builder.addCase(fetchChargerUptimeData.pending, (state) => {
      state.isLoadingHealth = true;
      state.error = null;
    });
    builder.addCase(fetchChargerUptimeData.fulfilled, (state, action) => {
      const { siteWithChargerData } = action.payload;

      state.selectedHealthSite = siteWithChargerData;
      state.isLoadingHealth = false;
      state.error = null;
    });
    builder.addCase(fetchChargerUptimeData.rejected, (state, action) => {
      state.isLoadingHealth = true;
      state.error = action.error.message ?? 'Something went wrong.';
    });

    /// Socket Site Info
    builder.addCase(fetchSocketUptimeData.pending, (state) => {
      state.isLoadingHealth = true;
      state.error = null;
    });
    builder.addCase(fetchSocketUptimeData.fulfilled, (state, action) => {
      const { siteWithSocketData } = action.payload;

      state.selectedHealthSite = siteWithSocketData;
      state.isLoadingHealth = false;
      state.error = null;
    });
    builder.addCase(fetchSocketUptimeData.rejected, (state, action) => {
      state.isLoadingHealth = true;
      state.error = action.error.message ?? 'Something went wrong.';
    });
  },
});

export const {
  site,
  setSelectedHealthSite,
  setHealthSiteByCustomDate,
  setAllChargerIds,
  clearHealthCustomQuarter,
  clearHealthSites,
} = healthSlice.actions;
export default healthSlice.reducer;
