import { ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import StatusKeyItem from './ChargerStatusKeyModal/StatusKeyItem';
import CircularLoader from '../../common/CircularLoader';

const SocketListItem = ({ isSelected, handleSocketSelect, socket }) => {
  const theme = useTheme();

  const { chargersStatus } = useSelector((state) => state.chargers);
  const { isLoadingFinancial } = useSelector((state) => state.sites);
  const { isLoadingHealth } = useSelector((state) => state.health);

  const svgStringCcs = `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_345_57453" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
  <rect y="0.608704" width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_345_57453)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.337 3.00111C19.9534 4.71232 20.8519 6.99812 20.842 9.37348C20.842 12.6945 19.1454 15.5935 
  16.6239 17.1418H17.7116C19.2148 17.1418 20.4334 18.3839 20.4334 19.916V20.2258C20.4334 21.758 19.2148 23 17.7116 23H7.12613C5.62291 23 4.40431 21.758 
  4.40431 20.2258V19.916C4.40431 18.3839 5.62291 17.1418 7.12613 17.1418H8.21897C5.69717 15.5935 4.00007 12.6945 4.00007 9.37348C3.99092 6.99808 4.8898 
  4.71242 6.50615 3.00111H18.337ZM11.04 6.8925C11.0402 7.0984 10.9805 7.29974 10.8684 7.47104C10.7563 7.64233 10.5968 7.77588 10.4102 7.85478C10.2236 7.93367 
  10.0183 7.95438 9.82011 7.91426C9.62196 7.87414 9.43995 7.77501 9.2971 7.62942C9.15425 7.48382 9.05699 7.2983 9.01763 7.09635C8.97827 6.89439 8.99858 6.68507 
  9.07599 6.49488C9.1534 6.30469 9.28443 6.14218 9.45249 6.02793C9.62055 5.91367 9.81809 5.8528 10.0201 5.85302C10.2905 5.85331 10.5497 5.96292 10.7409 6.1578C10.9321 6.35268 
  11.0397 6.6169 11.04 6.8925ZM14.1955 9.72997C14.195 10.0855 14.0912 10.433 13.897 10.7284C13.7029 11.0238 13.4272 11.2539 13.1047 11.3896C12.7823 11.5253 12.4276 11.5606 
  12.0855 11.4908C11.7434 11.4211 11.4293 11.2496 11.1829 10.9979C10.9364 10.7463 10.7687 10.4258 10.7009 10.077C10.6332 9.72823 10.6684 9.3668 
  10.8021 9.03841C10.9359 8.71001 11.1622 8.42941 11.4524 8.23208C11.7426 8.03475 12.0836 7.92954 12.4325 7.92976C12.9001 7.92976 13.3485 8.11907 
  13.6791 8.45605C14.0097 8.79303 14.1955 9.25008 14.1955 9.72664V9.72997ZM10.6466 20.1337C10.6466 20.489 10.5432 20.8365 10.3495 21.1319C10.1558 21.4274 
  9.88046 21.6578 9.55832 21.7938C9.23618 21.9298 8.8817 21.9653 8.53972 21.896C8.19774 21.8267 7.8836 21.6555 7.63705 21.4042C7.39049 21.1529 7.22258 20.8328 
  7.15456 20.4842C7.08653 20.1357 7.12145 19.7744 7.25488 19.446C7.38832 19.1177 7.61428 18.8371 7.9042 18.6396C8.19412 18.4422 8.53498 18.3368 8.88366 18.3368C9.11522 
  18.3366 9.34453 18.383 9.55849 18.4733C9.77245 18.5635 9.96685 18.6959 10.1306 18.8627C10.2943 19.0296 10.4242 19.2278 10.5127 19.4458C10.6013 19.6639 10.6468 19.8976 
  10.6466 20.1337ZM17.4515 21.1319C17.6453 20.8365 17.7486 20.489 17.7486 20.1337C17.7488 19.8976 17.7033 19.6639 17.6147 19.4458C17.5262 19.2278 17.3963 19.0296 17.2326 
  18.8627C17.0689 18.6959 16.8745 18.5635 16.6605 18.4733C16.4465 18.383 16.2172 18.3366 15.9857 18.3368C15.637 18.3368 15.2961 18.4422 15.0062 18.6396C14.7163 18.8371 14.4903 19.1177 
  14.3569 19.446C14.2235 19.7744 14.1885 20.1357 14.2566 20.4842C14.3246 20.8328 14.4925 21.1529 14.7391 21.4042C14.9856 21.6555 15.2998 21.8267 15.6417 21.896C15.9837 21.9653 16.3382 
  21.9298 16.6603 21.7938C16.9825 21.6578 17.2578 21.4274 17.4515 21.1319ZM15.7247 7.47104C15.8368 7.29974 15.8965 7.0984 15.8963 6.8925C15.896 6.6169 15.7885 6.35268 15.5973 6.1578C15.4061 5.96292 
  15.1469 5.85331 14.8765 5.85302C14.6744 5.8528 14.4769 5.91367 14.3088 6.02793C14.1408 6.14218 14.0097 6.30469 13.9323 6.49488C13.8549 6.68507 13.8346 6.89439 13.874 7.09635C13.9133 7.2983 14.0106 
  7.48382 14.1534 7.62942C14.2963 7.77501 14.4783 7.87414 14.6765 7.91426C14.8746 7.95438 15.08 7.93367 15.2666 7.85478C15.4532 7.77588 15.6126 7.64233 15.7247 7.47104Z" fill="${theme.palette.btnActiveColor}"/>
  </g>
  </svg>
   
`;

  const iconUrlCcs = `data:image/svg+xml;utf8,${encodeURIComponent(svgStringCcs)}`;

  const svgStringType2 = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_981_36655" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_981_36655)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.97305 3H19.0279C20.9466 4.99485 22.0126 7.65866 21.9999 10.4264C21.9999 16.1974 17.5246 20.8675 
12.001 20.8675C6.4774 20.8675 2.00011 16.1894 2.00011 10.4264C1.98768 7.65852 3.05407 4.99469 4.97305 3ZM10.3466 8.18377C10.3474 8.42389 10.2769 8.65884 
10.1441 8.85888C10.0112 9.05891 9.82202 9.21503 9.60041 9.30747C9.37879 9.3999 9.13473 9.4245 8.89912 9.37813C8.66352 9.33177 8.44697 9.21654 8.2769 9.04703C8.10683 
8.87751 7.99089 8.66134 7.94376 8.4259C7.89662 8.19045 7.92041 7.9463 8.01212 7.72438C8.10383 7.50247 8.25933 7.31275 8.45893 7.17926C8.65852 7.04578 8.89325 6.97452 9.13337
 6.97452C9.45513 6.97452 9.76372 7.10234 9.99124 7.32986C10.2188 7.55739 10.3466 7.86597 10.3466 8.18774V8.18377ZM8.47163 11.4926C8.47182 11.9073 8.349 12.3128 8.11871 12.6577C7.88841 13.0026 7.56098 
 13.2715 7.17785 13.4303C6.79471 13.5891 6.37308 13.6307 5.96629 13.5499C5.55951 13.469 5.18584 13.2693 4.89258 12.976C4.59931 12.6828 4.39961 12.3091 4.31874 11.9023C4.23788 11.4955 4.27948 11.0739 
 4.43829 10.6908C4.5971 10.3076 4.86597 9.98021 5.2109 9.74991C5.55583 9.51961 5.96132 9.39679 6.37606 9.39699C6.65125 9.39699 6.92375 9.45119 7.178 9.55651C7.43224 9.66182 7.66326 9.81618 
 7.85785 10.0108C8.05244 10.2054 8.2068 10.4364 8.31211 10.6906C8.41742 10.9449 8.47163 11.2174 8.47163 11.4926ZM13.7435 12.6567C13.9737 12.3121 14.0966 11.907 14.0966 11.4926C14.0963 10.9367 13.8753 
 10.4037 13.4821 10.0107C13.089 9.61774 12.5559 9.39699 12 9.39699C11.5856 9.39719 11.1805 9.52026 10.836 9.75064C10.4915 9.98102 10.2231 10.3084 10.0647 10.6913C9.9062 11.0742 9.86484 11.4956
  9.94581 11.902C10.0268 12.3084 10.2264 12.6817 10.5196 12.9747C10.8127 13.2677 11.1861 13.4671 
 11.5925 13.5479C11.999 13.6287 12.4203 13.5871 12.8032 13.4285C13.186 13.2699 13.5132 13.0013 13.7435 12.6567ZM10.9863 16.9579C11.2164 16.6134 11.3392 
 16.2083 11.3392 15.794H11.3362C11.3362 15.2382 11.1155 14.7052 10.7225 14.3122C10.3295 13.9192 9.79646 13.6984 9.24068 13.6984C8.82635 13.699 8.42148 13.8224 
 8.07726 14.053C7.73304 14.2837 7.46491 14.6111 7.30676 14.9941C7.14861 15.3771 7.10754 15.7983 7.18873 16.2046C7.26992 16.6109 7.46974 16.984 7.76293 17.2768C8.05612 
 17.5696 8.42952 17.7689 8.83594 17.8495C9.24235 17.9301 9.66355 17.8884 10.0463 17.7297C10.429 17.571 10.7561 17.3025 10.9863 16.9579ZM16.611 16.9582C16.8413 16.6136 16.9642 16.2084 
 16.9642 15.794C16.9642 15.2382 16.7434 14.7052 16.3504 14.3122C15.9574 13.9192 15.4244 13.6984 14.8686 13.6984C14.4541 13.6984 14.049 13.8213 13.7044 14.0516C13.3598 14.2818 13.0912 14.6091
 12.9326 14.992C12.774 15.375 12.7325 15.7963 12.8133 16.2028C12.8942 16.6093 13.0938 16.9827 13.3868 17.2758C13.6799 17.5688 14.0533 17.7684 14.4598 17.8493C14.8663 17.9301 15.2876 17.8886 15.6706 17.73C16.0535 
 17.5714 16.3807 17.3028 16.611 16.9582ZM19.3684 12.6567C19.5986 12.3121 19.7215 11.907 19.7215 11.4926C19.7212 10.9367 19.5002 10.4037 19.1071 10.0107C18.7139 9.61774 18.1808 9.39699 17.6249 9.39699C17.2105 9.39719 
 16.8055 9.52026 16.461 9.75064C16.1165 9.98102 15.8481 10.3084 15.6896 10.6913C15.5311 11.0742 15.4898 11.4956 15.5708 11.902C15.6517 12.3084 15.8514 12.6817 16.1445 12.9747C16.4376 13.2677 16.811 13.4671 17.2175
 13.5479C17.624 13.6287 18.0452 13.5871 18.4281 13.4285C18.811 13.2699 19.1382 13.0013 19.3684 12.6567ZM16.1186 8.18377C16.1194 8.42384 16.0489 8.65876 15.9161 8.85877C15.7833 9.05879 15.5942 9.21491 15.3726 9.30737C15.1511 9.39984 
 14.9071 9.42449 14.6715 9.37821C14.4359 9.33192 14.2194 9.21679 14.0493 9.04738C13.8792 8.87796 13.7631 8.66189 13.7159 8.4265C13.6686 8.19112 13.6923 7.94701 13.7838 7.72507C13.8754 7.50314 14.0307 7.31335 14.2302 7.17975C14.4297
  7.04614 14.6643 6.97472 14.9044 6.97452C15.2261 6.97452 15.5347 7.10234 15.7623 7.32986C15.9898 7.55739 16.1176 7.86597 16.1176 8.18774L16.1186 8.18377Z" fill="${theme.palette.btnActiveColor}"/>
</g>
</svg>  
`;
  const iconUrlType2 = `data:image/svg+xml;utf8,${encodeURIComponent(svgStringType2)}`;

  let connectorImg = iconUrlType2;

  switch (socket.connector_type) {
    case 'ccs':
      connectorImg = iconUrlCcs;
      break;
    case 'type2':
    default:
      break;
  }

  const foundStatus = chargersStatus.find((charger) => charger.id === socket.id) ?? 'AVAILABLE';

  if (isLoadingFinancial || isLoadingHealth) {
    return (
      <ListItem
        key={`socket-${socket.id}-item`}
        sx={isSelected ? { backgroundColor: '#EEEEEE !important' } : {}}
      >
        <ListItemText
          primary={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <div className="flex flex-row items-center">
                <div className="pl-6" />
                <StatusKeyItem chargerStatus={foundStatus.status} />
                <div className="w-4" />

                <Typography
                  className="font-customRegular"
                  variant="body2"
                  style={{
                    maxWidth: '12px',
                    cursor: 'pointer',
                    fontFamily: theme.typography.secondaryFontFamily,
                  }}
                >
                  {socket?.manual_code ?? ''}
                </Typography>
              </div>
              <div className="px-4">
                <CircularLoader />
              </div>
            </div>
          }
        />
      </ListItem>
    );
  }

  return (
    <ListItem sx={isSelected ? { backgroundColor: '#EEEEEE !important' } : {}}>
      <ListItemText
        primary={
          <div
            onClick={() => handleSocketSelect(socket)}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <div className="flex flex-row items-center">
              <div className="pl-6" />
              <StatusKeyItem chargerStatus={foundStatus.status} />
              <div className="w-4" />
              <Typography
                className="font-customRegular"
                variant="body2"
                style={{
                  maxWidth: '12px',
                  cursor: 'pointer',
                  fontFamily: theme.typography.secondaryFontFamily,
                }}
              >
                {socket.manual_code}
              </Typography>
            </div>
            <span className="px-6">
              <img alt="logo" src={connectorImg} />
            </span>
          </div>
        }
      />
    </ListItem>
  );
};

export default SocketListItem;

SocketListItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  handleSocketSelect: PropTypes.func.isRequired,
  socket: PropTypes.object.isRequired,
  socketIndex: PropTypes.number.isRequired,
};
