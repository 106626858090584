import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { SitesRepository } from '../repositories/sites_repository/sites_repository';
import { BasicSite } from '../repositories/sites_repository/models/sites_repository_models';

export const fetchInitialSiteInfo = createAsyncThunk('sites/fetch', async (_, { getState }) => {
  try {
    const userState = getState().user;

    const portfolioId = userState.user.pid;
    const siteId = userState.user.sid;
    let initialPortfolioData = null;
    let initialSiteData = null;
    let newPortfolioData = null;

    if (portfolioId) {
      initialPortfolioData = await SitesRepository.getInitialPortfolioData(
        portfolioId,
        userState.user.token,
      );

      newPortfolioData = {
        end: initialPortfolioData.portfolioData.end,
        start: initialPortfolioData.portfolioData.start,
        label: initialPortfolioData.portfolioData.label,
        totalDays: initialPortfolioData.portfolioData.totalDays,
        quarters: initialPortfolioData.portfolioData.quarters,
        sites: initialPortfolioData.sites,
        description: initialPortfolioData.portfolioData.description,
        displayRevenue: initialPortfolioData.portfolioData.displayRevenue,
        displayUptime: initialPortfolioData.portfolioData.displayUptime,
      };
      newPortfolioData.sites = newPortfolioData.sites.map((site) => {
        site.displayRevenue = newPortfolioData.displayRevenue;
        site.displayUptime = newPortfolioData.displayUptime;
        return site;
      });
    } else {
      initialSiteData = await SitesRepository.getSiteData(siteId, userState.user.token);
    }

    return {
      portfolioData: newPortfolioData,
      siteData: initialSiteData,
    };
  } catch (error) {
    console.error('Error fetching site information:', error);
  }
});

export const fetchSiteInfo = createAsyncThunk('site/fetch', async (siteId, { getState }) => {
  try {
    const userState = getState().user;
    const token = userState.user.token;
    return await SitesRepository.getSiteData(siteId, token);
  } catch (error) {
    console.error('Error fetching site information:', error);
  }
});

export const fetchChargerSiteInfo = createAsyncThunk(
  'sites/charger/fetch',
  async (newSelectedSite, { getState }) => {
    try {
      const userState = getState().user;
      const token = userState.user.token;
      const siteWithChargerData = await SitesRepository.setDataByCharger(newSelectedSite, token);

      return {
        siteWithChargerData,
      };
    } catch (error) {
      console.error('Error fetching site information:', error);
    }
  },
);

export const fetchSocketSiteInfo = createAsyncThunk(
  'sites/socket/fetch',
  async (newSelectedSite, { getState }) => {
    try {
      const userState = getState().user;

      const token = userState.user.token;

      const siteWithSocketData = await SitesRepository.setDataBySocket(newSelectedSite, token);

      return {
        siteWithSocketData,
      };
    } catch (error) {
      console.error('Error fetching site information:', error);
    }
  },
);

const initialState = {
  portfolioFinancialData: null,
  siteData: null,
  customQToShow: null,
  selectedFinancialSite: null,
  isLoadingFinancial: false,
  error: null,
  allAvailableFinancialQuarters: null,
};

const financialSlice = createSlice({
  name: 'financial',
  initialState,
  reducers: {
    setSelectedFinancialSite: (state, action) => {
      const { newSelectedSite } = action.payload;

      state.selectedFinancialSite = newSelectedSite;
    },
    setAllChargerIds: (state, action) => {
      state.allChargerIds = action.payload;
    },
    setFinancialSiteByCustomDate: (state, action) => {
      const selectedSite = current(state).selectedFinancialSite;
      const { customDateFrom, customDateTo } = action.payload;

      let newQToShow = null;
      if (selectedSite.siteInfo) {
        newQToShow = SitesRepository.getSelectedPortfolioWithCustomDate(
          selectedSite,
          customDateFrom,
          customDateTo,
        );
      }
      state.customQToShow = newQToShow;
    },
    clearFinancialCustomQuarter: (state) => {
      state.customFinancialQToShow = null;
    },
    clearFinancialSites: (state) => {
      state.portfolioFinancialData = null;
      state.siteData = null;
      state.selectedFinancialSite = null;
      state.isLoadingFinancial = false;
      state.error = null;
      state.allAvailableFinancialQuarters = null;
    },
  },
  extraReducers: (builder) => {
    /// All Sites Info
    builder.addCase(fetchInitialSiteInfo.pending, (state) => {
      state.isLoadingFinancial = true;
      state.error = null;
    });
    builder.addCase(fetchInitialSiteInfo.fulfilled, (state, action) => {
      const siteData = action.payload.siteData;
      const portfolioData = action.payload.portfolioData;
      let allAvailableQuarters = [];
      if (siteData) {
        const newSelectedSite = new BasicSite(siteData);
        state.selectedFinancialSite = newSelectedSite;
        allAvailableQuarters = siteData.quarters.map((q) => ({
          id: q.id,
          label: q.label,
          start: q.start,
          end: q.end,
        }));
      } else if (portfolioData) {
        allAvailableQuarters = action.payload.portfolioData.quarters.map((q) => ({
          id: q.id,
          label: q.label,
          start: q.start,
          end: q.end,
        }));
      }

      state.allAvailableFinancialQuarters = allAvailableQuarters;
      state.portfolioFinancialData = action.payload.portfolioData;
      state.siteData = siteData ?? null;
      state.isLoadingFinancial = false;
      state.error = null;
    });
    builder.addCase(fetchInitialSiteInfo.rejected, (state, action) => {
      state.isLoadingFinancial = true;
      state.error = action.error.message ?? 'Something went wrong.';
    });

    // Single Site Info
    builder.addCase(fetchSiteInfo.pending, (state) => {
      state.isLoadingFinancial = true;
      state.error = null;
    });
    builder.addCase(fetchSiteInfo.fulfilled, (state, action) => {
      const siteData = action.payload;
      const newSelectedSite = new BasicSite(siteData);
      state.selectedFinancialSite = newSelectedSite;
      state.allAvailableFinancialQuarters = siteData.quarters.map((q) => ({
        id: q.id,
        label: q.label,
        start: q.start,
        end: q.end,
      }));
      state.isLoadingFinancial = false;
      state.error = null;
    });
    builder.addCase(fetchSiteInfo.rejected, (state, action) => {
      state.isLoadingFinancial = true;
      state.error = action.error.message ?? 'Something went wrong.';
    });
    /// Charger Site Info
    builder.addCase(fetchChargerSiteInfo.pending, (state) => {
      state.isLoadingFinancial = true;
      state.error = null;
    });
    builder.addCase(fetchChargerSiteInfo.fulfilled, (state, action) => {
      const { siteWithChargerData } = action.payload;

      state.selectedFinancialSite = siteWithChargerData;
      state.isLoadingFinancial = false;
      state.error = null;
    });
    builder.addCase(fetchChargerSiteInfo.rejected, (state, action) => {
      state.isLoadingFinancial = true;
      state.error = action.error.message ?? 'Something went wrong.';
    });

    /// Socket Site Info
    builder.addCase(fetchSocketSiteInfo.pending, (state) => {
      state.isLoadingFinancial = true;
      state.error = null;
    });
    builder.addCase(fetchSocketSiteInfo.fulfilled, (state, action) => {
      const { siteWithSocketData } = action.payload;

      state.selectedFinancialSite = siteWithSocketData;
      state.isLoadingFinancial = false;
      state.error = null;
    });
    builder.addCase(fetchSocketSiteInfo.rejected, (state, action) => {
      state.isLoadingFinancial = true;
      state.error = action.error.message ?? 'Something went wrong.';
    });
  },
});

export const {
  site,
  setSelectedFinancialSite,
  setFinancialSiteByCustomDate,
  setAllChargerIds,
  clearFinancialCustomQuarter,
  clearFinancialSites,
} = financialSlice.actions;
export default financialSlice.reducer;
