import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setViewSiteDetails } from '../store/uiSlice';
import { Button, useTheme } from '@mui/material';
import { ReactComponent as HomeIcon } from '../../src/images/home.svg';
import { ReactComponent as SiteIcon } from '../../src/images/siteLocation.svg';
import { IoMdHeart } from 'react-icons/io';

export const NavButtons = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const location = useLocation();
  const { selectedHealthSite } = useSelector((state) => state.health);
  const canOverride = useSelector((state) => state.user.user.pid === 'M110QfzAZ1yxPfqFqT6X');
  const isManagedService = selectedHealthSite?.siteInfo?.model === 'Managed Service';

  const [activeButton, setActiveButton] = useState(() => {
    if (location.pathname === '/site-detail') {
      return 'SiteDetails';
    } else if (location.pathname === '/health') {
      return 'Health';
    } else {
      return 'Summary';
    }
  });

  const handleButtonClick = (button) => {
    setActiveButton(button);

    dispatch(setViewSiteDetails(button === 'SiteDetails'));
  };

  return (
    <div className="flex pb-4 pl-14">
      <Link to={'/'}>
        <Button
          onClick={() => handleButtonClick('Summary')}
          defaultChecked
          sx={{
            position: 'relative',
            overflow: 'hidden',
            height: '60px',
            textTransform: 'none',
            paddingLeft: '25px',
            paddingTop: '10px',
            borderRadius: '25px 0 0 25px',
            boxShadow: 'none',
            backgroundColor: theme.palette.btnBackgroundColor,
            color: theme.palette.btnActiveColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            '&:hover': {
              boxShadow: 'none',
              color: theme.palette.btnHoverTxt,
              backgroundColor: theme.palette.btnHoverColor,
            },
            ...(activeButton === 'Summary' && {
              '&::after': {
                content: '""',
                borderRadius: '25px 25px 0 0',
                position: 'relative',
                width: '95%',
                height: '6px',
                top: '6px',
                color: theme.palette.btnActiveColor,
                backgroundColor: theme.palette.underline,
              },
            }),
          }}
          variant="contained"
        >
          <HomeIcon fill={theme.palette.primaryColorTxt} sx={{ marginBottom: '5px' }} size={24} />
          Summary
        </Button>
      </Link>
      {(selectedHealthSite?.siteInfo?.displayUptime || (canOverride && isManagedService)) && (
        <Link to={'/health'}>
          <Button
            onClick={() => handleButtonClick('Health')}
            sx={{
              position: 'relative',
              overflow: 'hidden',
              height: '60px',
              textTransform: 'none',
              paddingRight: '25px',
              paddingTop: '10px',
              boxShadow: 'none',
              backgroundColor: theme.palette.btnBackgroundColor,
              color: theme.palette.btnActiveColor,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              '&:hover': {
                boxShadow: 'none',
                color: theme.palette.btnHoverTxt,
                backgroundColor: theme.palette.btnHoverColor,
              },
              ...(activeButton === 'Health' && {
                '&::after': {
                  content: '""',
                  borderRadius: '25px 25px 0 0',
                  position: 'relative',
                  width: '95%',
                  height: '6px',
                  top: '6px',
                  color: theme.palette.btnActiveColor,
                  backgroundColor: theme.palette.underline,
                },
              }),
            }}
            variant="contained"
          >
            <IoMdHeart
              color={theme.palette.primaryColorTxt}
              sx={{ marginBottom: '5px' }}
              size={24}
            />
            Health
          </Button>
        </Link>
      )}
      <Link to={'/site-detail'}>
        <Button
          onClick={() => handleButtonClick('SiteDetails')}
          sx={{
            position: 'relative',
            overflow: 'hidden',
            height: '60px',
            textTransform: 'none',
            paddingRight: '25px',
            paddingTop: '10px',
            borderRadius: '0 25px 25px 0',
            boxShadow: 'none',
            backgroundColor: theme.palette.btnBackgroundColor,
            color: theme.palette.btnActiveColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            '&:hover': {
              boxShadow: 'none',
              color: theme.palette.btnHoverTxt,
              backgroundColor: theme.palette.btnHoverColor,
            },
            ...(activeButton === 'SiteDetails' && {
              '&::after': {
                content: '""',
                borderRadius: '25px 25px 0 0',
                position: 'relative',
                width: '95%',
                height: '6px',
                top: '6px',
                color: theme.palette.btnActiveColor,
                backgroundColor: theme.palette.underline,
              },
            }),
          }}
          variant="contained"
        >
          <SiteIcon fill={theme.palette.primaryColorTxt} sx={{ marginBottom: '5px' }} size={24} />
          Site details
        </Button>
      </Link>
    </div>
  );
};
