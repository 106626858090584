import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material';

import moment from 'moment';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { setCustomDateFrom, setCustomDateTo } from '../../store/uiSlice';

export const DateRangePicker = ({ label, isStart }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { customDateFrom, customDateTo, availableDataMinDate, availableDataMaxDate } = useSelector(
    (state) => state.ui,
  );

  const handleDateFromChange = (newValue) => {
    let date = moment(newValue).startOf('day');
    const minDateMoment = moment(availableDataMinDate);
    if (isStart) {
      if (date.isBefore(minDateMoment)) {
        date = minDateMoment;
      }
    } else {
      if (date.isSame(moment(), 'day')) {
        date = moment(availableDataMaxDate);
      } else {
        date = date.clone().endOf('day');
      }
    }
    if (isStart) {
      dispatch(setCustomDateFrom(date.toDate()));
    } else {
      dispatch(setCustomDateTo(date.toDate()));
    }
  };

  const disableKeyboardEntry = (e) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        className="customPicker"
        slotProps={{
          textField: {
            placeholder: label,
            onBeforeInput: disableKeyboardEntry,
          },
        }}
        disableFuture
        format="DD/MM/YYYY"
        value={isStart ? moment(customDateFrom) : moment(customDateTo)}
        onChange={handleDateFromChange}
        minDate={availableDataMinDate ? moment(availableDataMinDate) : null}
        maxDate={availableDataMaxDate ? moment(availableDataMaxDate) : null}
        sx={{
          width: '40% ',
          '& label.Mui-focused': {
            color: theme.palette.dropdownColor,
          },
          svg: { color: theme.palette.dropdownColor },
        }}
      />
    </LocalizationProvider>
  );
};

DateRangePicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  isStart: PropTypes.bool,
};
