import React from 'react';

interface DashboardCardProps {
  title: string;
  value: string | number;
  showDurationKey?: boolean;
  Icon: React.ElementType;
}

const DashboardCard = ({ title, value, showDurationKey, Icon }: DashboardCardProps) => {
  return (
    <div
      className={`flex flex-col w-full h-24 items-center justify-center overflow-hidden text-center border shadow-sm bg-dash-card-bg font-custom-bold order border-stone-300 rounded-2xl`}
    >
      <p className="text-[16px] font-semibold">{value === 'NaN' ? '--' : value}</p>
      <div className="flex justify-center text-[14px]">{title}</div>
      {showDurationKey && (
        <div className="flex justify-center">
          <span className="text-[12px]">(HH:MM:SS)</span>
        </div>
      )}
    </div>
  );
};

export default DashboardCard;
