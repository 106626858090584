import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  themeSelect: null,
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      if (state.user && state.user.email.includes('@ncp')) {
        state.themeSelect = 'NCP';
        document.documentElement.style.setProperty('--color-primary-3', '#FCDB38');
        document.documentElement.style.setProperty('--primary-bg-color', '#FFFFFF');
        document.documentElement.style.setProperty('--secondary-bg-color', '#FFFFFF');
        document.documentElement.style.setProperty(
          '--custom-bold-font',
          'ConduitITC-Bold, san-serif',
        );
        document.documentElement.style.setProperty('--secondary-font-color', '#FFFFFF');
        document.body.style.fontFamily = 'ConduitITC-Regular, sans-serif';
        document.documentElement.style.setProperty(
          '--custom-bold-font',
          'ConduitITC-Bold, san-serif',
        );
        document.documentElement.style.setProperty('--color-neutral-11', '#F1F1F2');
        document.documentElement.style.setProperty('--color-neutral-12', '#00000');
      } else {
        state.themeSelect = 'EVC';
        document.documentElement.style.setProperty('--color-primary-3', '#24712A');
        document.documentElement.style.setProperty('--primary-bg-color', '#FAFAFA');
        document.documentElement.style.setProperty('--secondary-font-color', '#24712A');
        document.body.style.fontFamily = 'Montserrat, sans-serif';
        document.documentElement.style.setProperty('--custom-bold-font', 'Montserrat, san-serif');
        document.documentElement.style.setProperty('--color-neutral-11', '#FFFFFF');
        document.documentElement.style.setProperty('--color-neutral-12', '##F6F6F6');
      }
    },
    logout: (state) => {
      state.user = null;
    },
  },
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
