import { createTheme } from '@mui/material/styles';

let primaryFontFamily = 'Montserrat, sans-serif';
let secondaryFontFamily = 'Montserrat, sans-serif';
let white = '#000';
let primaryColorTxt = '#24712A';
let dropdownColor = '#2f9638';
let btnBackGround = '#E5F2E7';
let navUnderline = '#24712A';

const themeEVC = createTheme({
  palette: {
    button: {
      main: primaryColorTxt,
    },
    btnBackgroundColor: btnBackGround,
    btnActiveColor: primaryColorTxt,
    btnHoverTxt: white,
    btnHoverColor: primaryColorTxt,
    dropdownColor: dropdownColor,
    underline: navUnderline,
  },
  typography: {
    fontFamily: primaryFontFamily,
    secondaryFontFamily: secondaryFontFamily,
  },
});
export default themeEVC;
