import React from 'react';

import { Site } from '../../repositories/sites_repository/models/sites_repository_models';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import MapMarker from './components/MapMarker';
import SiteUtils from '../../repositories/sites_repository/utils/site_utils';
import mapStyle from './map_style';

interface MapProps {
  site: Site;
}

export default function Map({ site }: MapProps) {
  const isAllSites = site.id === 'All Sites';
  const centerGeo = isAllSites ? SiteUtils.getCentralGeoCoordinate(site.allSitesGeo) : site.geo;

  const { isLoaded } = useJsApiLoader({
    id: 'EVC-Client-Portal-Map',
    googleMapsApiKey: 'AIzaSyAxSrC6b3pcbGVJBWO1q7C4Tc0fLJYZJPY',
  });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  const siteMarkerDetails = [];
  if (isAllSites) {
    for (let i = 0; i < site.allSitesGeo.length; i++) {
      const siteGeo = site.allSitesGeo[i];
      const siteName = site.allSitesNames[i];

      siteMarkerDetails.push({ geo: siteGeo, name: siteName });
    }
  }

  return (
    <GoogleMap
      key={1}
      mapContainerStyle={{
        width: '60vw',
        height: '50vh',
        display: 'flex',
      }}
      options={mapStyle}
      center={{
        lat: centerGeo.lat,
        lng: centerGeo.lng,
      }}
      zoom={isAllSites ? 8 : 14}
    >
      {isAllSites ? (
        siteMarkerDetails.map((e) => {
          return <MapMarker markerPosition={e.geo} markerLabel={e.name} />;
        })
      ) : (
        <MapMarker markerPosition={site.geo} markerLabel={site.siteName} />
      )}
    </GoogleMap>
  );
}
