import axios from 'axios';
import { ResetPasswordResponse, RegisterClientPortalUserResponse } from './models/auth_repository_models'

const prodBaseUrl = 'https://europe-west2-prod-evc-app.cloudfunctions.net/evc_auth';
const devBaseUrl = 'https://europe-west2-dev-evc-app-4d345.cloudfunctions.net/evc_auth'
const baseUrl = process.env.REACT_APP_ENV === 'development' ? devBaseUrl : prodBaseUrl

export abstract class AuthRepository {
  static async sendResetPasswordEmail(email: string): Promise<ResetPasswordResponse> {
    try {
      const response = await axios.post(
        `${baseUrl}/send_reset_password_email`,
        {
          email,
        },
      );
      
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  static async registerClientPortalUser(firstName: string, lastName: string, email: string, password: string): Promise<RegisterClientPortalUserResponse> {
    try {
      const response = await axios.post(
        `${baseUrl}/client_portal/register_user`,
        {
          firstName,
          lastName,
          email,
          password
        },
      );
      
      return {msg: response.data.msg, statusCode: response.status};
    } catch (error) {
      throw error;
    }
  }
}
