/* eslint-disable react/prop-types */
import React from 'react';
import DashboardCard from './DashboardCard';
import {
  BsFillLightningChargeFill,
  BsPlugFill,
  BsCurrencyPound,
  BsListCheck,
  BsSpeedometer,
  BsBarChartLineFill,
  BsTreeFill,
  BsTrophyFill,
} from 'react-icons/bs';
import { useSelector } from 'react-redux';
import DashboardUptimeCard from './DashboardUptimeCard';
import DashboardLiveStatusCard from './DashboardLiveStatusCard';

export default function DashboardCardsList(props) {
  const { qToShow } = props;
  const { selectedFinancialSite } = useSelector((state) => state.sites);
  const canOverride = useSelector((state) => state.user.user.pid === 'M110QfzAZ1yxPfqFqT6X');
  const isManagedService = selectedFinancialSite.siteInfo.model === 'Managed Service';

  const { selectedHealthSite } = useSelector((state) => state.health);

  return (
    <div className="flex flex-row items-start justify-start w-full h-full">
      <div className="flex flex-col h-full space-y-[20px] ">
        {(selectedHealthSite?.siteInfo?.displayUptime || (canOverride && isManagedService)) && <DashboardUptimeCard qToShow={qToShow} />}
        <DashboardLiveStatusCard />
      </div>
      <div className="w-[20px]" />
      <div className="flex flex-col w-full h-full ">
        <div className="flex flex-row space-x-[20px] ">
          {(selectedFinancialSite.siteInfo.displayRevenue || (canOverride && isManagedService)) && (
            <DashboardCard
              title="Total Revenue"
              value={'£ ' + qToShow.financialData.totalRevenue.toLocaleString()}
              Icon={BsCurrencyPound}
            />
          )}
          <DashboardCard
            title="Total Sessions"
            value={qToShow.financialData.sessionsCount.toLocaleString()}
            Icon={BsListCheck}
          />
        </div>
        <div className="h-[20px]" />
        <div className="flex flex-row space-x-[20px]">
          <DashboardCard
            title="Total kWh"
            value={
              typeof qToShow.financialData.totalKwh === 'number'
                ? qToShow.financialData.totalKwh.toLocaleString()
                : qToShow.financialData.totalKwh
            }
            Icon={BsFillLightningChargeFill}
          />
          <DashboardCard
            title="Total Sockets"
            value={qToShow.financialData.totalSockets}
            Icon={BsPlugFill}
          />

          <DashboardCard
            title="kWh/Session"
            value={qToShow.financialData.avgKwh}
            Icon={BsFillLightningChargeFill}
          />
        </div>
        <div className="h-[20px]" />
        <div className="flex flex-row space-x-[20px]">
          <DashboardCard
            title="kWh/Day"
            value={qToShow.financialData.kwhDay}
            Icon={BsFillLightningChargeFill}
          />

          <DashboardCard
            title="kWh/Socket/Day"
            value={qToShow.financialData.avgKwhSocketDay}
            Icon={BsFillLightningChargeFill}
          />

          <DashboardCard
            title="Sessions/Day"
            value={qToShow.financialData.avgSessionsDay}
            Icon={BsBarChartLineFill}
          />
        </div>
        <div className="h-[20px]" />
        <div className="flex flex-row space-x-[20px]">
          <DashboardCard
            title="Utilisation %"
            value={
              typeof qToShow.financialData.avgUtilisationDay === 'number'
                ? (qToShow.financialData.avgUtilisationDay * 100).toFixed(2)
                : qToShow.financialData.avgUtilisationDay
            }
            Icon={BsSpeedometer}
          />
          <DashboardCard
            title="Carbon Saved (kg)"
            value={qToShow.financialData.carbonSaved.toLocaleString()}
            Icon={BsTreeFill}
          />
          <DashboardCard
            title="EV Miles"
            value={qToShow.financialData.savedEVMiles.toLocaleString()}
            Icon={BsTrophyFill}
          />
        </div>
      </div>
    </div>
  );
}
