import { useState } from 'react';
import auth from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  Link,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ReactComponent as EVCicon } from '../../src/images/powered_by_evc.svg';

const SignInScreen = () => {
  const isDev = process.env.REACT_APP_ENV === 'development';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      toast.error('Something has gone wrong - contact support');
    }
    navigate('/');
  };

  return (
    <>
      <div className="flex flex-col justify-center h-screen bg-center bg-no-repeat bg-cover place-items-center bg-journey-image brightness-50">
        <div className="relative">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
      <div className="w-[400px] p-[20px] pt-[24px] bg-white rounded-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <p className="flex mb-4 text-3xl font-[600] text-[28px]">Charge Portal Login</p>

        <form className="grid flex-col" onSubmit={submitFormHandler}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
            <OutlinedInput
              className="mb-[12px]"
              id="outlined-adornment-email"
              type="text"
              label="Email"
              value={email || ''}
              onChange={(e) => setEmail(e.target.value.trim())}
            />
          </FormControl>

          <FormControl className="pt-3" variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              fullWidth
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ color: '#2F9638' }} />
                    ) : (
                      <Visibility sx={{ color: '#2F9638' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              value={password || ''}
              onChange={(e) => setPassword(e.target.value.trim())}
            />
          </FormControl>

          <div className="w-full pt-3">
            <Button
              sx={{ borderRadius: 8, height: '40px', width: '100%' }}
              variant="contained"
              color="success"
              type="submit"
            >
              Sign In
            </Button>
          </div>
          <div className="inline-flex justify-center mt-7">
            <span>
              <p className="mb-4 text-base font-bold text-md">Powered by</p>
            </span>
            <span className="pt-1 mb-4 ml-3 font-bold text-md" style={{ marginTop: '-1px' }}>
              <EVCicon />
            </span>
            {isDev && <span className="mb-4 ml-3 font-bold text-md">[DEV]</span>}
          </div>
          <Link
            sx={{ marginBottom: '12px', fontSize: '14px' }}
            className="flex justify-center"
            onClick={() => navigate('/forgot-password')}
            color="#24712A"
          >
            {'Forgotten your password?'}
          </Link>
          {/* <Link
            sx={{ marginBottom: '12px', fontSize: '14px' }}
            className="flex justify-center"
            onClick={() => navigate('/register')}
            color="#24712A"
          >
            {'Need an account?'}
          </Link> */}
        </form>
      </div>
    </>
  );
};

export default SignInScreen;
