import axios from 'axios';
import { BasicSite, PortfolioSites, Quarter, Site } from './models/sites_repository_models';
import moment from 'moment';
import SiteUtils from './utils/site_utils';
import { ChargePoint } from '../models/models';

const prodBaseUrl = 'https://europe-west2-prod-evc-app.cloudfunctions.net';
const devBaseUrl = 'https://europe-west2-dev-evc-app-4d345.cloudfunctions.net'
const baseUrl = process.env.REACT_APP_ENV === 'development' ? devBaseUrl : prodBaseUrl


export abstract class SitesRepository {
  static async getInitialPortfolioData(pid: string, token: string): Promise<PortfolioSites> {
    try {
      const response = await axios.get(
        `${baseUrl}/evc_data/client_portfolio_sites_data?pid=${pid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data as PortfolioSites;
    } catch (error) {
      console.log(error);
      throw Error('There was an error getting the portfolio data');
    }
  }

  static async getSiteData(sid: string, token: string): Promise<Site | null> {
    try {
      const response = await axios.get(
        `${baseUrl}/evc_data/client_portfolio_sites_data?sid=${sid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data as Site;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  static async setDataByCharger(siteData: BasicSite, token: string): Promise<BasicSite> {
    const chargerSocketsIds = siteData.selectedCharger?.sockets.map((socket) => socket.id);
    const totalChargepoints = siteData.siteInfo.chargepoints;
    const uniqueChargepoints = [];

    if (!chargerSocketsIds) {
      throw Error('No charger sockets found');
    }
    let startUrl = `${baseUrl}/evc_data/client_charger_socket_data?sid=${siteData.siteInfo.id}`;
    for (let i = 0; i < chargerSocketsIds.length; i++) {
      const id = chargerSocketsIds[i];

      startUrl += `&chargerId${i + 1}=${id}`;
    }

    const response = await axios.get(startUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const newSite = response.data as Site;
    const newChargepoint = newSite.chargepoints[0];
    const repeatedChargerTxIndex = totalChargepoints.findIndex(
      (e) => e.name === newChargepoint.name,
    );

    for (let i = 0; i < totalChargepoints.length; i++) {
      if (repeatedChargerTxIndex === i) {
        uniqueChargepoints.push(newChargepoint);
      } else {
        uniqueChargepoints.push(totalChargepoints[i]);
      }
    }

    return setNewMergedSite(newSite, siteData, uniqueChargepoints);
  }

  static async setDataBySocket(siteData: BasicSite, token: string): Promise<BasicSite> {
    const totalChargepoints = siteData.siteInfo.chargepoints;
    const uniqueChargepoints = [] as Array<ChargePoint>;

    const response = await axios.get(
      `${baseUrl}/evc_data/client_charger_socket_data?sid=${siteData.siteInfo.id}&chargerId1=${siteData.selectedSocket?.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const newSite = response.data as Site;
    const updatedChargepoint = newSite.chargepoints[0];
    updatedChargepoint.sockets.forEach((s) => {
      s.manualCode = s.manual_code ?? s.manualCode;
    });

    const repeatedChargepointIndex = totalChargepoints.findIndex(
      (e) => e.name === updatedChargepoint.name,
    );

    const nonUpdatedSocket = siteData.selectedCharger?.sockets.find(
      (s) => s.id !== siteData.selectedSocket?.id,
    );

    updatedChargepoint.sockets.push(nonUpdatedSocket!);

    const orderedSockets = updatedChargepoint.sockets.sort((a, b) => {
      const firstManualCode = a.manual_code ?? a.manualCode;
      const secondManualCode = b.manual_code ?? b.manualCode;
      if (firstManualCode && secondManualCode) {
        return firstManualCode.localeCompare(secondManualCode);
      }
      return firstManualCode?.localeCompare(secondManualCode);
    });

    updatedChargepoint.sockets = orderedSockets;

    for (let i = 0; i < totalChargepoints.length; i++) {
      if (repeatedChargepointIndex === i) {
        uniqueChargepoints.push(updatedChargepoint);
      } else {
        uniqueChargepoints.push(totalChargepoints[i]);
      }
    }

    return setNewMergedSite(newSite, siteData, uniqueChargepoints);
  }

  static getSelectedPortfolioWithCustomDate(
    siteData: BasicSite,
    dateFrom: Date,
    dateTo: Date,
  ): Quarter {
    const totalDays = moment(dateTo).diff(moment(dateFrom), 'days') + 1;
    const id = `qCustom-${dateFrom.toISOString()}_${dateTo.toISOString()}`;
    const allTxs = siteData.siteInfo.quarters[0].financialData.transactions;

    const allChargers = siteData?.siteInfo.chargepoints.map((e) => e);
    const allChargersSockets = allChargers.map((e) => e.sockets).flat();
    const selectedChargerSocketsIds = siteData?.selectedCharger?.sockets.map((e) => e.id) ?? [];
    const selectedSocketId = siteData?.selectedSocket?.id ?? '';

    const financialData = SiteUtils.calculateFinancialData(
      allTxs,
      allChargersSockets,
      selectedChargerSocketsIds,
      selectedSocketId,
      dateFrom,
      dateTo,
      totalDays,
    );

    return {
      id: id,
      start: moment(dateFrom),
      end: moment(dateTo),
      label: `Custom: ${moment(dateFrom).format('DD/MM/YYYY')} - ${moment(dateTo).format(
        'DD/MM/YYYY',
      )}`,
      totalDays: totalDays,
      financialData: financialData,
    } as Quarter;
  }
}

/// This builds a new BasicSite instance, used for when user selects a new charger or socket
const setNewMergedSite = (
  newSite: Site,
  oldSiteData: BasicSite,
  uniqueChargepoints: Array<ChargePoint>,
) => {
  const workedMergedSite = {
    siteName: newSite.siteName,
    geo: newSite.geo,
    siteAddress1: newSite.siteAddress1,
    sitePostcode: newSite.sitePostcode,
    isPublic: newSite.isPublic,
    client: newSite.client,
    model: newSite.model,
    siteCrm: newSite.siteCrm,
    siteType: newSite.siteType,
    timestamp: newSite.timestamp,
    clientContactPerson: newSite.clientContactPerson,
    clientContactNo: newSite.clientContactNo,
    clientContactEmail: newSite.clientContactEmail,
    siteAddress2: newSite.siteAddress2,
    siteCounty: newSite.siteCounty,
    baysAtLocation: newSite.baysAtLocation,
    chargerPowerRates: newSite.chargerPowerRates,
    chargerSpeed: newSite.chargerSpeed,
    quarters: newSite.quarters,
    hasAvailableCharger: newSite.hasAvailableCharger,
    chargepoints: uniqueChargepoints,
    chargerPaymentMethods: newSite.chargerPaymentMethods,
    chargerConnectionType: newSite.chargerConnectionType,
    eConnection: newSite.eConnection,
    isDiscoverable: newSite.isDiscoverable,
    updatedTimestamp: newSite.updatedTimestamp,
    id: newSite.id,
    allSitesGeo: newSite.allSitesGeo,
    allSitesNames: newSite.allSitesNames,
    displayRevenue: newSite.displayRevenue,
    displayUptime: newSite.displayUptime,
  } as Site;

  return new BasicSite(workedMergedSite, oldSiteData.selectedCharger, oldSiteData.selectedSocket);
};
