import { useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import PropTypes from 'prop-types';
import moment from 'moment';

export default function HealthUptimeCard({ qToShow }) {
  const theme = useTheme();
  const uptimeAvg = qToShow.uptimeData.uptimeAvg;

  const startDate = moment(qToShow.start).format('Do MMM YYYY');
  const endDate = moment(qToShow.end).format('Do MMM YYYY');

  const [chartData, setChartData] = useState(() => {
    if (isNaN(uptimeAvg) || !uptimeAvg) {
      return [
        { name: 'Uptime', percentage: 0 },
        { name: 'Downtime', percentage: 100 },
      ];
    }
    return [
      { name: 'Uptime', percentage: uptimeAvg },
      { name: 'Downtime', percentage: 100 - uptimeAvg },
    ];
  });

  const COLORS = [theme.palette.dropdownColor, theme.palette.btnBackgroundColor];

  useEffect(() => {
    if (qToShow) {
      let dataToUse = [
        { name: 'Uptime', percentage: qToShow.uptimeData.uptimeAvg },
        { name: 'Downtime', percentage: 100 - qToShow.uptimeData.uptimeAvg },
      ];
      if (isNaN(uptimeAvg) || !uptimeAvg) {
        dataToUse = [
          { name: 'Uptime', percentage: 0 },
          { name: 'Downtime', percentage: 100 },
        ];
      }
      setChartData(dataToUse);
    }
  }, [qToShow, uptimeAvg]);

  let uptimeAvgText = `Operable ${uptimeAvg}% of the time between ${startDate} - ${endDate}`;

  if (isNaN(uptimeAvg) || !uptimeAvg) {
    uptimeAvgText = 'Uptime data is not available for the time selected';
  }

  return (
    <div className="flex flex-col items-start w-1/2 h-full p-4 overflow-hidden text-center border shadow-sm bg-dash-card-bg font-custom-bold order border-stone-300 rounded-2xl">
      <h1 className="font-bold text-md">Uptime</h1>
      <div className="h-2" />
      <div className="relative flex flex-col items-center justify-center w-full">
        <div className="relative flex items-center ">
          <PieChart width={200} height={200}>
            <Pie
              data={chartData}
              dataKey="percentage"
              outerRadius={90}
              innerRadius={60}
              startAngle={-90}
              endAngle={270}
              style={{ cursor: 'pointer', borderRadius: '50%' }}
            >
              {chartData.map((_, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
          <div className="absolute inset-0 flex items-center justify-center text-[16px] font-bold text-black w-[200px] h-[200px]">{`${
            uptimeAvg ? `${uptimeAvg}%` : '-'
          }`}</div>
        </div>
        <div className="h-8" />
        <div className="flex-wrap ">
          <p className="text-sm">{uptimeAvgText}</p>
        </div>
      </div>
      <div className="h-4" />
    </div>
  );
}

HealthUptimeCard.propTypes = {
  qToShow: PropTypes.any.isRequired,
};
