import React from 'react';
import { ColorRing } from 'react-loader-spinner';

export default function CircularLoader() {
  return (
    <ColorRing
      visible={true}
      height={24}
      width={24}
      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
    />
  );
}
