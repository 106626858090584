import React, { useCallback, useEffect } from 'react';
import { Header } from '../components/Header';
import { NavButtons } from '../components/navButtons';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInitialSiteInfo } from '../store/financialSlice';
import { fetchChargersStatus } from '../store/chargerSlice';
import { SiteSelect } from '../components/SiteSelect/SiteSelect';
import { ReactComponent as EvcIcon } from '../images/evcLogoWhite.svg';
import { ReactComponent as NcpIcon } from '../images/NCPLOGO.svg';
import { fetchInitialUptimeData } from '../store/healthSlice';

const Portal = () => {
  const dispatch = useDispatch();
  const { themeSelect } = useSelector((state) => state.user);

  const updateChargerStatus = useCallback(() => {
    dispatch(fetchChargersStatus());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchInitialSiteInfo());
    dispatch(fetchChargersStatus());
    dispatch(fetchInitialUptimeData());
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(updateChargerStatus, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [updateChargerStatus]);

  const selectedTheme = themeSelect === 'NCP' ? <NcpIcon /> : <EvcIcon />;

  return (
    <div className="flex overflow-x-clip">
      <div className="w-[6vw] bg-side-bar-color h-auto">
        <div className="pt-16 ml-3">{selectedTheme}</div>
      </div>
      <div className="flex flex-col w-full ">
        <Header />
        <NavButtons />
        <div className="flex w-full pr-10 ">
          <div className="w-2/6 ">
            <SiteSelect />
          </div>
          <div className="w-full h-full overflow-y-scroll">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portal;
