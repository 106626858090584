import React from 'react';
import { Marker } from '@react-google-maps/api';
import { BasicGeo } from '../../../repositories/models/models';
import { useTheme } from '@mui/material';

interface MapMarkerProps {
  markerPosition: BasicGeo;
  markerLabel: string;
}

export default function MapMarker({ markerPosition, markerLabel }: MapMarkerProps) {
  const theme: any = useTheme();

  const svgString = `<svg width="48" height="54" viewBox="0 0 48 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_201_7285)">
<mask id="mask0_201_7285" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="6" y="1" width="36" height="36">
<rect x="6" y="1" width="36" height="36" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_201_7285)">
<path d="M23.9998 34C19.9748 30.575 16.9685 27.3938 14.981 24.4563C12.9935 21.5188 11.9998 18.8 11.9998 16.3C11.9998 12.55 13.206 9.5625 15.6185 7.3375C18.031 5.1125 20.8248 4 23.9998 4C27.1748 4 29.9685 5.1125 32.381 7.3375C34.7935 9.5625 35.9998 12.55 35.9998 16.3C35.9998 18.8 35.006 21.5188 33.0185 24.4563C31.031 27.3938 28.0248 30.575 23.9998 34Z" fill="${theme.palette.btnActiveColor}"/>
<mask id="mask1_201_7285" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="18" y="10" width="12" height="12">
<rect x="18" y="10" width="12" height="12" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask1_201_7285)">
<path d="M22 21L22.5 17.5H20L24.5 11H25.5L25 15H28L23 21H22Z" fill="#E5F2E7"/>
</g>
</g>
</g>
<defs>
<filter id="filter0_d_201_7285" x="-6" y="-3" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="8"/>
<feGaussianBlur stdDeviation="6"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_201_7285"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_201_7285" result="shape"/>
</filter>
</defs>
</svg>`;

  const iconUrl = `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`;

  return (
    <Marker
      zIndex={99}
      position={markerPosition}
      label={{ text: markerLabel, color: 'black', fontSize: '14px', className: 'mt-8' }}
      icon={iconUrl}
    />
  );
}
