import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customDateSelected: false,
  viewSiteDetails: false,
  selectedQuarterLabel: null,
  customDateFrom: null,
  customDateTo: null,
  availableDataMinDate: null,
  availableDataMaxDate: null,
  availableFinancialDataMinDate: null,
  availableHealthDataMinDate: null,
  selectedTimePeriod: {
    id: '',
    label: 'All Time',
    start: '',
    end: '',
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    customizeDate: (state, action) => {
      state.customDateSelected = action.payload;
    },
    setViewSiteDetails: (state, action) => {
      state.viewSiteDetails = action.payload;
    },
    setSelectedTimePeriod: (state, action) => {
      state.selectedTimePeriod = action.payload;
    },
    setCustomDateFrom: (state, action) => {
      state.customDateFrom = action.payload;
    },
    setCustomDateTo: (state, action) => {
      state.customDateTo = action.payload;
    },
    setAvailableDataMinDate: (state, action) => {
      state.availableDataMinDate = action.payload;
    },
    setAvailableDataMaxDate: (state, action) => {
      state.availableDataMaxDate = action.payload;
    },
    setAvailableFinancialDataMinDate: (state, action) => {
      state.availableFinancialDataMinDate = action.payload;
    },

    setInitialUI: (state) => {
      state.customDateSelected = false;
      state.viewSiteDetails = false;
      state.selectedQuarterLabel = null;
      state.customDateFrom = null;
      state.customDateTo = null;
      state.availableDataMinDate = null;
      state.availableDataMaxDate = null;
      state.selectedTimePeriod = {
        id: '',
        label: 'All Time',
        start: '',
        end: '',
      };
    },
    setQuarterLabel: (state, action) => {
      state.selectedQuarterLabel = action.payload;
    },
  },
});

export const {
  customizeDate,

  setAllSites,
  setViewSiteDetails,
  setInitialUI,
  setQuarterLabel,
  setCustomDateFrom,
  setCustomDateTo,
  setAvailableDataMinDate,
  setAvailableDataMaxDate,
  setSelectedTimePeriod,
} = uiSlice.actions;
export default uiSlice.reducer;
