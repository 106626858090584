import React from 'react';
import PropTypes from 'prop-types';

export default function HealthIssueType({ title, issuesNumbers }) {
  return (
    <div className="flex flex-row items-center justify-start h-8 px-2 border shadow-sm w-fit bg-dash-card-bg font-custom-bold order border-stone-300 rounded-xl">
      <div className="relative flex items-center justify-center h-5 bg-red-700 rounded-full w-9">
        <span className="absolute text-xs text-white ">
          {issuesNumbers >= 10000 ? '+9999' : issuesNumbers}
        </span>
      </div>
      <div className="w-2" />
      <p className="text-sm">{title}</p>
    </div>
  );
}

HealthIssueType.propTypes = {
  title: PropTypes.string.isRequired,
  issuesNumbers: PropTypes.any.isRequired,
};
