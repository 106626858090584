import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Cell } from 'recharts';
import PropTypes from 'prop-types';
import { checkUptimeInQuarter } from '../../../utils/helper';

export default function DashboardUptimeCard({ qToShow }) {
  const theme = useTheme();
  const { selectedHealthSite, customHealthQToShow } = useSelector((state) => state.health);
  const { customDateSelected } = useSelector((state) => state.ui);

  const { startDate, endDate, uptimeData } = checkUptimeInQuarter(
    (customHealthQToShow && customDateSelected ? customHealthQToShow : null) ?? qToShow,
    selectedHealthSite?.siteInfo?.quarters,
    customHealthQToShow && customDateSelected ? true : false,
  );

  const uptimeAvg = uptimeData?.uptimeAvg ?? 0;

  const [chartData, setChartData] = useState(() => {
    if (isNaN(uptimeAvg) || !uptimeAvg) {
      return [
        { name: 'Uptime', percentage: 0 },
        { name: 'Downtime', percentage: 100 },
      ];
    }
    return [
      { name: 'Uptime', percentage: uptimeAvg },
      { name: 'Downtime', percentage: 100 - uptimeAvg },
    ];
  });
  const COLORS = [theme.palette.dropdownColor, theme.palette.btnBackgroundColor];

  useEffect(() => {
    let dataToUse = [
      { name: 'Uptime', percentage: uptimeAvg },
      { name: 'Downtime', percentage: 100 - uptimeAvg },
    ];
    if (isNaN(uptimeAvg) || !uptimeAvg) {
      dataToUse = [
        { name: 'Uptime', percentage: 0 },
        { name: 'Downtime', percentage: 100 },
      ];
    }
    setChartData(dataToUse);
  }, [uptimeAvg]);

  let uptimeAvgText = `Operable ${uptimeAvg}% of the time between ${startDate} - ${endDate}`;

  if (isNaN(uptimeAvg) || !uptimeAvg) {
    uptimeAvgText = 'Uptime data is not available for the time selected';
  }

  return (
    <div className="flex flex-col items-start px-4 py-5 overflow-hidden text-center border shadow-sm h-52 w-72 bg-dash-card-bg font-custom-bold order border-stone-300 smd:w-40 rounded-2xl">
      <h1 className="font-bold text-md">Uptime</h1>
      <div className="h-2" />
      <div className="relative flex flex-row items-center">
        <PieChart width={100} height={100}>
          <Pie
            data={chartData}
            dataKey="percentage"
            outerRadius={50}
            innerRadius={35}
            startAngle={-90}
            endAngle={270}
            style={{ cursor: 'pointer', borderRadius: '50%' }}
          >
            {chartData.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
        <div
          className={`flex absolute text-[16px] font-bold text-black w-[100px] h-[100px]items-center justify-center`}
        >{`${uptimeAvg ? `${uptimeAvg}%` : '-'}`}</div>
        <div className="w-2" />
        <div className="flex-wrap w-36">
          <p className="text-sm">{uptimeAvgText}</p>
        </div>
      </div>
    </div>
  );
}

DashboardUptimeCard.propTypes = {
  qToShow: PropTypes.any.isRequired,
};
