import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import React, { useEffect, useState } from 'react';

import SiteTransactionsLocal from './components/SiteTransactionsLocal';
import DashboardCardsList from './components/DashboardCardsList';
import Map from '../Map/Map';
import { DateRangePicker } from '../common/DateRangePicker';
import { setFinancialSiteByCustomDate } from '../../store/financialSlice';
import { setHealthSiteByCustomDate } from '../../store/healthSlice';
import { Loader } from '../common/Loader';
import {
  setQuarterLabel,
  setCustomDateFrom,
  setCustomDateTo,
  setAvailableDataMinDate,
  setAvailableDataMaxDate,
} from '../../store/uiSlice';

import { useLocation } from 'react-router-dom';
import { createEmptyQuarter } from '../../utils/helper';
import QuarterSelector from '../common/QuarterSelector';

export const Summary = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { allAvailableFinancialQuarters, selectedFinancialSite, customQToShow } = useSelector(
    (state) => state.sites,
  );

  const { customDateSelected, selectedTimePeriod, customDateFrom, customDateTo } = useSelector(
    (state) => state.ui,
  );

  const [qToShow, setQToShow] = useState(() => {
    return selectedFinancialSite?.siteInfo.quarters[0];
  });

  useEffect(() => {
    if (selectedFinancialSite && selectedTimePeriod && !customDateSelected) {
      const selectedSiteAvailableQuarters = selectedFinancialSite.siteInfo.quarters.map(
        (q) => q.label,
      );
      const quarterMatchingSelectedTimePeriod = selectedFinancialSite.siteInfo.quarters.find(
        (q) => q.label === selectedTimePeriod.label,
      );

      if (selectedSiteAvailableQuarters.includes(selectedTimePeriod.label)) {
        setQToShow(quarterMatchingSelectedTimePeriod);

        dispatch(setAvailableDataMinDate(quarterMatchingSelectedTimePeriod.start));
        dispatch(setAvailableDataMaxDate(quarterMatchingSelectedTimePeriod.end));
        dispatch(setCustomDateFrom(null));
        dispatch(setCustomDateTo(null));
      } else {
        setQToShow(
          createEmptyQuarter(
            selectedTimePeriod.label,
            selectedTimePeriod.start,
            selectedTimePeriod.end,
          ),
        );

        dispatch(setAvailableDataMinDate(selectedFinancialSite?.siteInfo.quarters[0].start));
        dispatch(setAvailableDataMaxDate(selectedFinancialSite?.siteInfo.quarters[0].end));
        dispatch(setCustomDateFrom(null));
        dispatch(setCustomDateTo(null));
      }
    }
  }, [selectedFinancialSite, selectedTimePeriod, location.pathname, customDateSelected, dispatch]);

  // Runs when user select Custom in dropdown and both dates are selected
  useEffect(() => {
    if (customDateTo && customDateFrom && customDateSelected) {
      const customLabel = `${moment(customDateFrom).format('DD-MM-YYYY')} ${moment(
        customDateTo,
      ).format('DD-MM-YYYY')}`;
      dispatch(setQuarterLabel(customLabel));
      dispatch(setFinancialSiteByCustomDate({ customDateFrom, customDateTo }));
      dispatch(
        setHealthSiteByCustomDate({
          healthCustomDateFrom: customDateFrom,
          healthCustomDateTo: customDateTo,
        }),
      );
    }
  }, [customDateTo, customDateFrom, customDateSelected, selectedFinancialSite, dispatch]);

  useEffect(() => {
    if (customDateSelected && customQToShow) {
      setQToShow(customQToShow);
    }
  }, [customQToShow, customDateSelected]);

  if (!selectedFinancialSite || !qToShow || !qToShow.financialData) {
    return (
      <div className="flex flex-col items-start min-w-[930px] h-screen  w-[65vw]  ">
        <div className="flex flex-row items-center justify-start w-full pl-4 border shadow-sm rounded-2xl bg-dash-card-bg">
          <div className="flex items-center justify-start h-16 pt-2 pb-1 pl-4 text-2xl font-semibold bg-dash-panel-bg font-custom-bold">
            Summary
          </div>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start min-w-[930px]  w-[65vw]  ">
      <div className="flex flex-row items-center justify-start w-full pl-4 border shadow-sm rounded-2xl bg-dash-card-bg">
        <div className="flex items-center justify-start h-16 pt-2 pb-1 pl-4 text-2xl font-semibold bg-dash-panel-bg font-custom-bold">
          Summary
        </div>
        <div className="flex-1" />
        <div className="flex flex-row items-center justify-end w-full">
          {customDateSelected ? (
            <div className="flex justify-end mr-0">
              <div className="flex items-center justify-end h-16 pt-2 pr-4">
                <DateRangePicker label="Start date" isStart />
                <span className="px-2">-</span>
                <DateRangePicker label="End date" />
              </div>
            </div>
          ) : (
            <span className="justify-end pr-2 text-lg font-customRegular">
              {`${moment(qToShow.start).format('Do MMM YYYY')} - ${moment(qToShow.end).format(
                'Do MMM YYYY',
              )}`}
            </span>
          )}
          <div className="w-4" />
          <QuarterSelector
            quartersToSelect={allAvailableFinancialQuarters}
            isCustomDateSelected={customDateSelected}
            defaultValue={selectedFinancialSite.siteInfo.quarters[0]}
            qToShow={qToShow}
          />
        </div>
      </div>

      <div className="w-full h-auto py-4">
        <DashboardCardsList qToShow={qToShow} />
      </div>

      {qToShow ? (
        <div className="w-full pt-4 bg-white border rounded-2xl">
          <div className="flex items-center justify-start pl-4 ">
            <div className="pt-5 pl-4 text-2xl font-semibold font-custom-bold">
              Last Charging Sessions
            </div>
          </div>
          <div className="flex items-start justify-start w-full ">
            <SiteTransactionsLocal qToShow={qToShow} />
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <div className="w-full pt-4 mt-6 bg-white border rounded-2xl">
        <div className="flex items-center justify-start w-full px-4 rounded">
          <div className="pt-5 pl-4 text-2xl font-semibold font-custom-bold">Locations</div>
        </div>
        <div className="h-4" />
        <div className="flex justify-center px-8 pt-3 pb-8 rounded">
          <Map site={selectedFinancialSite.siteInfo} />
        </div>
      </div>
      <div className="h-4" />
    </div>
  );
};
