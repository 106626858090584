import moment from 'moment';

export function displayTariff(tariff) {
  if (tariff['1'] === '') {
    return 'Free Charging';
  } else if (tariff['2'] === '0') {
    return (
      'PAYG: £' + (tariff['1'] / 100).toFixed(2) + ', Connect: £' + (tariff['4'] / 100).toFixed(2)
    );
  } else if (tariff['1'] === '0') {
    return 'Membership: £' + (tariff['2'] / 100).toFixed(2);
  } else {
    return (
      'PAYG: £' +
      (tariff['1'] / 100).toFixed(2) +
      ', Connect: £' +
      (tariff['4'] / 100).toFixed(2) +
      ' | Membership: £' +
      (tariff['2'] / 100).toFixed(2)
    );
  }
}

export function displayChargers(chargers) {
  let returnText = '';
  //console.log(chargers)
  chargers.forEach((element) => {
    returnText += '* ' + element + '\n';
  });

  return returnText;
}

const emptyQuarter = {
  id: 'empty-quarter',
  start: '',
  end: '',
  label: '',
  total_days: '-',
  kwh_socket_day: '-',
  financialData: {
    totalRevenue: '-',
    totalKwh: '-',
    sessionsCount: '-',
    totalSockets: '-',
    kwhDay: '-',
    avgKwhSocketDay: '-',
    avgKwh: '-',
    savedEVMiles: '-',
    carbonSaved: '-',
    avgUtilisationDay: '-',
    avgSessionsDay: '-',
    transactions: [],
  },
};

export function createEmptyQuarter(label, start, end) {
  return { ...emptyQuarter, label, start, end };
}

const emptyHealthQuarter = {
  id: 'empty-quarter',
  label: '',
  start: '',
  end: '',
  uptimeData: {
    uptimeAvg: null,
    downtimeAvg: null,
    unavailableAvg: null,
    totalUnavailableStatus: null,
    faultedAvg: null,
    totalFaultedStatus: null,
    unknownAvg: null,
    totalUnknownStatus: null,
    suspendedEVSEAvg: null,
    totalSuspendedEVSEStatus: null,
    suspendedEVAvg: null,
    totalSuspendedEVStatus: null,
    chargingAvg: null,
    totalChargingStatus: null,
    preparingAvg: null,
    totalPreparingStatus: null,
    finishingAvg: null,
    totalFinishingStatus: null,
    availableAvg: null,
    totalAvailableStatus: null,
    uptimes: [],
  },
};

export function createEmptyHealthQuarter(label, start, end) {
  return { ...emptyHealthQuarter, label, start, end };
}

export function checkUptimeInQuarter(qToShow, healthQuarters, isCustom) {
  let uptimeData = null;
  let startDate = null;
  let endDate = null;

  if (!qToShow || !healthQuarters) {
    return {
      startDate,
      endDate,
      uptimeData,
    };
  }

  const qStart = moment(qToShow.start);
  const qEnd = moment(qToShow.end);

  if (isCustom) {
    startDate = moment(qToShow.start).format('Do MMM YYYY');
    endDate = moment(qToShow.end).format('Do MMM YYYY');
    return {
      startDate,
      endDate,
      uptimeData: qToShow.uptimeData,
    };
  }

  healthQuarters.forEach((hq) => {
    const hqStart = moment(hq.start);
    const hqEnd = moment(hq.end);

    if (hq.id === qToShow.id || (hqStart.isSameOrAfter(qStart) && hqEnd.isSameOrBefore(qEnd))) {
      uptimeData = hq.uptimeData;
      startDate = moment(hq.start).format('Do MMM YYYY');
      endDate = moment(hq.end).format('Do MMM YYYY');
    }
  });

  return {
    startDate,
    endDate,
    uptimeData,
  };
}
