import React from 'react';
import HealthIssueType from './HealthIssuesType';
import PropTypes from 'prop-types';

export default function HealthIssuesTypesCard({ qToShow }) {
  return (
    <div className="flex flex-col items-start w-full p-4 overflow-hidden text-center border shadow-sm bg-dash-card-bg font-custom-bold order border-stone-300 smd:w-40 rounded-2xl">
      <h1 className="font-bold text-md">Issues Type</h1>
      <div className="h-6" />
      <div className="flex flex-row items-center justify-start w-full ">
        <HealthIssueType
          title="Unavailable"
          issuesNumbers={qToShow.uptimeData.totalUnavailableStatus ?? '-'}
        />
        <div className="w-4" />
        <HealthIssueType title="Faulted" issuesNumbers={qToShow.uptimeData.totalFaultedStatus ?? '-'} />
      </div>
    </div>
  );
}

HealthIssuesTypesCard.propTypes = {
  qToShow: PropTypes.any.isRequired,
};
