import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, OutlinedInput, InputLabel, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthRepository } from '../repositories/auth_repository/auth_repository';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const onEmailChange = (email) => {
    setErrorMessage('');
    setEmail(email);
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      await AuthRepository.sendResetPasswordEmail(email);
      toast.success('A password reset email has been sent');
      navigate('/sign-in');
    } catch (error) {
      if (error.response?.data?.error?.code === 'auth/email-not-found') {
        setErrorMessage("This user doesn't exist");
        setIsLoading(false);
      } else {
        toast.error('Something has gone wrong - contact support');
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center h-screen bg-center bg-no-repeat bg-cover place-items-center bg-journey-image brightness-50">
        <div className="relative">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
      <div className="w-[400px] h-[310px] p-[20px] pt-[24px] bg-white rounded-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <h1 className="flex mb-4 text-3xl font-[600] text-[28px]">Reset your password</h1>
        <p className="text-[18px] font-[400] mb-4">
          Receive a link to reset your password by typing your email below
        </p>
        <form className="grid flex-col" onSubmit={submitFormHandler}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              type="text"
              label="Email"
              value={email || ''}
              onChange={(e) => onEmailChange(e.target.value.trim())}
              error={errorMessage}
            />
          </FormControl>
          {errorMessage && <p className="text-xs text-red-700">{errorMessage}</p>}

          <div className="w-full pt-3 mt-[12px]">
            <Button
              sx={{ borderRadius: 8, height: '40px', width: '100%' }}
              variant="contained"
              color="success"
              type="submit"
              disabled={isLoading}
            >
              Retrieve Link
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
