import { GeoPoint } from 'firebase/firestore';
import { PortfolioSites } from '../models/sites_repository_models';
import moment from 'moment';
import {
  ChargePoint,
  ChargerSocket,
  EVCTransaction,
  BasicGeo,
  EVCUptime,
  KwhPerChargepoint,
} from '../../models/models';

const evMilesMultiplier = 3;
const carbonSavedMultiplier = 0.2;
const targetKwh = 11.2;

export default abstract class SiteUtils {
  static createAllSiteMock = (portfolio: PortfolioSites, allChargepoints: Array<ChargePoint>) => {
    const { quarters, sites } = portfolio;

    const isFullyFunded = sites.every((site) => site.model === 'Fully Funded');
    const isManagedService = sites.every((site) => site.model === 'Managed Service');
    const model = isFullyFunded ? 'Fully Funded' : isManagedService ? 'Managed Service' : 'Mixed';

    let siteType = sites[0].siteType;

    for (let i = 1; i < sites.length; i++) {
      // If any element is different from the first element, return "Mixed"
      if (sites[i].siteType !== siteType) {
        siteType = 'Mixed';
      }
    }

    const allLocationsArray = sites.map((site) => site.geo);

    return {
      siteName: 'All Sites',
      geo: new GeoPoint(0, 0),
      siteAddress1: 'All Sites',
      sitePostcode: 'All Sites',
      isPublic: true,
      client: 'All Sites',
      model: model,
      siteCrm: 'All Sites',
      siteType: siteType,
      timestamp: 0,
      clientContactPerson: 'All Sites',
      clientContactNo: 'All Sites',
      clientContactEmail: 'All Sites',
      siteAddress2: 'All Sites',
      siteCounty: 'All Sites',
      baysAtLocation: 0,
      chargerPowerRates: [0],
      chargerSpeed: ['All Sites'],
      quarters: quarters,
      hasAvailableCharger: true,
      chargepoints: allChargepoints,
      chargerPaymentMethods: ['All Sites'],
      chargerConnectionType: ['All Sites'],
      eConnection: 'All Sites',
      isDiscoverable: true,
      updatedTimestamp: 0,
      id: 'All Sites',
      allSitesGeo: allLocationsArray,
      allSitesNames: sites.map((site) => site.siteName),
      displayRevenue: portfolio.displayRevenue,
      displayUptime: portfolio.displayUptime,
    };
  };

  static getCentralGeoCoordinate = (geoCoordinates: BasicGeo[]): BasicGeo => {
    if (geoCoordinates.length === 1) {
      return geoCoordinates[0];
    }

    let x = 0;
    let y = 0;
    let z = 0;

    geoCoordinates.forEach((geo) => {
      let latitude = (geo.lat * Math.PI) / 180;
      let longitude = (geo.lng * Math.PI) / 180;

      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    });

    let total = geoCoordinates.length;

    x /= total;
    y /= total;
    z /= total;

    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
      lat: (centralLatitude * 180) / Math.PI,
      lng: (centralLongitude * 180) / Math.PI,
    } as BasicGeo;
  };

  static calculateFinancialData = (
    transactionsToEvaluate: EVCTransaction[],
    allChargersSockets: ChargerSocket[],
    selectedChargerSocketsIds: string[],
    selectedSocketId: string,
    dateFrom: Date,
    dateTo: Date,
    totalDays: number,
  ) => {
    const workedStartDate = moment(dateFrom);
    const workedEndDate = moment(dateTo);
    const totalSockets = allChargersSockets.length;

    let totalKwh = 0;
    let totalRevenue = 0;
    const foundTxs: EVCTransaction[] = [];
    const kwhPerCharger: KwhPerChargepoint[] = [];

    for (const tx of transactionsToEvaluate) {
      const txDate = moment(new Date(tx.startCharger?.timestampInSecs));

      if (selectedSocketId && selectedChargerSocketsIds.length > 0) {
        if (selectedChargerSocketsIds && !selectedChargerSocketsIds.includes(tx.chargerId))
          continue;
        if (selectedSocketId && selectedSocketId !== tx.chargerId) continue;
      }

      let revenue = 0;
      let kwh = 0;
      if (txDate.isSameOrAfter(workedStartDate) && txDate.isSameOrBefore(workedEndDate)) {
        const currentChargepoint = allChargersSockets.find((s) => s.id === tx.chargerId);
        if (tx.externalData) {
          revenue = tx.externalData.calculatedCost;
          kwh = parseFloat(tx.externalData?.reportedKwh ?? 0);
        } else {
          revenue = tx.chargerStats?.calculatedCost ?? 0;
          kwh = parseFloat(tx.chargerStats?.kwh ?? '0');
        }
        totalKwh += kwh;
        totalRevenue += roundToTwo(revenue);
        foundTxs.push(tx);

        const kwhInSocket = {
          chargerFriendlyName: currentChargepoint?.charger_name ?? '',
          kwh: kwh,
        } as KwhPerChargepoint;

        if (kwhPerCharger.length > 0) {
          const chargepointIndex = kwhPerCharger.findIndex(
            (s) => s.chargerFriendlyName === currentChargepoint?.charger_name,
          );
          if (chargepointIndex !== -1) {
            kwhPerCharger[chargepointIndex].kwh += kwh;
          } else {
            kwhPerCharger.push(kwhInSocket);
          }
        } else {
          kwhPerCharger.push(kwhInSocket);
        }
      }
    }

    const avgKwhDayPerChargepoint = kwhPerCharger.map((k) => {
      return {
        chargerName: k.chargerFriendlyName,
        avgKwhDay: roundToTwo(k.kwh / totalDays),
      };
    });

    const avgUtilisationDayPerChargepoint = avgKwhDayPerChargepoint.map((k) => {
      return {
        chargerName: k.chargerName,
        avgUtilisationDay: roundToTwo(k.avgKwhDay / (targetKwh * 24)),
      };
    });

    const avgUtilisationDay =
      avgUtilisationDayPerChargepoint.reduce((acc, k) => {
        return acc + k.avgUtilisationDay;
      }, 0) / avgUtilisationDayPerChargepoint.length;

    const kwhDay = totalKwh / totalDays;
    const avgKwhSocketDay = roundToTwo(kwhDay / totalSockets);
    let avgKwh = 0;
    if (totalKwh > 0) {
      avgKwh = roundToTwo(totalKwh / foundTxs.length);
    }
    const savedEVMiles = roundToTwo(totalKwh * evMilesMultiplier);
    const carbonSaved = roundToTwo(totalKwh * carbonSavedMultiplier);
    const avgSessionsDay = roundToTwo(foundTxs.length / totalDays);

    return {
      totalRevenue: roundToTwo(totalRevenue),
      totalKwh: roundToTwo(totalKwh),
      sessionsCount: foundTxs.length,
      totalSockets: totalSockets,
      kwhDay: roundToTwo(kwhDay),
      avgKwhSocketDay: avgKwhSocketDay,
      avgKwh: avgKwh,
      savedEVMiles: savedEVMiles,
      carbonSaved: carbonSaved,
      avgUtilisationDay: avgUtilisationDay,
      avgSessionsDay: avgSessionsDay,
      transactions: foundTxs,
    };
  };

  static calculateCustomUptimeData = (
    allUptimes: EVCUptime[],
    selectedChargerSocketsIds: string[],
    selectedSocketId: string,
    dateFrom: Date,
    dateTo: Date,
    totalDays: number,
  ) => {
    const workedStartDate = moment(dateFrom);
    const workedEndDate = moment(dateTo);

    let uptimeAvg = 0;
    let downtimeAvg = 0;

    let totalUnavailableStatus = 0;
    let totalUnavailableDuration = 0;

    let totalFaultedStatus = 0;
    let totalFaultedDuration = 0;

    let totalUnknownStatus = 0;
    let totalUnknownDuration = 0;

    let totalSuspendedEVSEStatus = 0;
    let totalSuspendedEVSEDuration = 0;

    let totalSuspendedEVStatus = 0;
    let totalSuspendedEVDuration = 0;

    let totalChargingStatus = 0;
    let totalChargingDuration = 0;

    let totalPreparingStatus = 0;
    let totalPreparingDuration = 0;

    let totalFinishingStatus = 0;
    let totalFinishingDuration = 0;

    let totalAvailableStatus = 0;
    let totalAvailableDuration = 0;

    const foundUptimes: EVCUptime[] = [];

    for (const ut of allUptimes) {
      const utDate = moment(ut.statusDay);

      if (selectedSocketId && selectedChargerSocketsIds.length > 0) {
        if (selectedChargerSocketsIds && !selectedChargerSocketsIds.includes(ut.socketId)) continue;
        if (selectedSocketId && selectedSocketId !== ut.socketId) continue;
      }

      if (utDate.isSameOrAfter(workedStartDate) && utDate.isSameOrBefore(workedEndDate)) {
        if (ut.unavailableDuration > 0) {
          totalUnavailableStatus += 1;
          totalUnavailableDuration += ut.unavailableDuration;
        }

        if (ut.faultedDuration > 0) {
          totalFaultedStatus += 1;
          totalFaultedDuration += ut.faultedDuration;
        }

        if (ut.unknownDuration > 0) {
          totalUnknownStatus += 1;
          totalUnknownDuration += ut.unknownDuration;
        }

        if (ut.suspendedEvseDuration > 0) {
          totalSuspendedEVSEStatus += 1;
          totalSuspendedEVSEDuration += ut.suspendedEvseDuration;
        }

        if (ut.suspendedEvDuration > 0) {
          totalSuspendedEVStatus += 1;
          totalSuspendedEVDuration += ut.suspendedEvDuration;
        }

        if (ut.chargingDuration > 0) {
          totalChargingStatus += 1;
          totalChargingDuration += ut.chargingDuration;
        }

        if (ut.preparingDuration > 0) {
          totalPreparingStatus += 1;
          totalPreparingDuration += ut.preparingDuration;
        }

        if (ut.finishingDuration > 0) {
          totalFinishingStatus += 1;
          totalFinishingDuration += ut.finishingDuration;
        }

        if (ut.availableDuration > 0) {
          totalAvailableStatus += 1;
          totalAvailableDuration += ut.availableDuration;
        }
        uptimeAvg += ut.uptimePercentage ?? 0;
        downtimeAvg += ut.downtimePercentage ?? 0;
        foundUptimes.push(ut);
      }
    }

    uptimeAvg = roundToTwo(uptimeAvg / foundUptimes.length);
    downtimeAvg = roundToTwo(downtimeAvg / foundUptimes.length);

    const unavailableAvg = Math.floor(totalUnavailableDuration / totalDays);

    const faultedAvg = Math.floor(totalFaultedDuration / totalDays);

    const unknownAvg = Math.floor(totalUnknownDuration / totalDays);

    const suspendedEVSEAvg = Math.floor(totalSuspendedEVSEDuration / totalDays);
    const suspendedEVAvg = Math.floor(totalSuspendedEVDuration / totalDays);
    const chargingAvg = Math.floor(totalChargingDuration / totalDays);
    const preparingAvg = Math.floor(totalPreparingDuration / totalDays);
    const finishingAvg = Math.floor(totalFinishingDuration / totalDays);
    const availableAvg = Math.floor(totalAvailableDuration / totalDays);

    return {
      uptimeAvg,
      downtimeAvg,
      unavailableAvg,
      totalUnavailableStatus,
      faultedAvg,
      totalFaultedStatus,
      unknownAvg,
      totalUnknownStatus,
      suspendedEVSEAvg,
      totalSuspendedEVSEStatus,
      suspendedEVAvg,
      totalSuspendedEVStatus,
      chargingAvg,
      totalChargingStatus,
      preparingAvg,
      totalPreparingStatus,
      finishingAvg,
      totalFinishingStatus,
      availableAvg,
      totalAvailableStatus,
      uptimes: foundUptimes,
    };
  };
}

function roundToTwo(num: number): number {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
