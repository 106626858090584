import moment from 'moment';
import { ChargePoint, ChargerSocket, EVCTransaction, BasicGeo } from '../../models/models';

export interface InitialSitesParams {
  sid?: string;
  pid?: string;
}

export class BasicSite {
  siteInfo: Site;
  selectedCharger?: ChargePoint;
  selectedSocket?: ChargerSocket;
  constructor(siteInfo: Site, selectedCharger?: ChargePoint, selectedSocket?: ChargerSocket) {
    this.siteInfo = siteInfo;
    this.selectedCharger = selectedCharger;
    this.selectedSocket = selectedSocket;
  }
}

export interface PortfolioSites {
  start: moment.Moment;
  end: moment.Moment;
  label: string;
  totalDays: number;
  quarters: Quarter[];
  sites: Site[];
  description: string;
  sitesGeo: BasicGeo[];
  displayRevenue:boolean;
  displayUptime:boolean;
}

export interface Site {
  siteName: string;
  geo: BasicGeo;
  siteAddress1: string;
  sitePostcode: string;
  isPublic: boolean;
  client: string;
  model: string;
  siteCrm: string;
  siteType: string;
  timestamp: number;
  clientContactPerson: string;
  clientContactNo: string;
  clientContactEmail: string;
  siteAddress2: string;
  siteCounty: string;
  baysAtLocation: number;
  chargerPowerRates: number[];
  chargerSpeed: string[];
  hasAvailableCharger: boolean;
  chargerPaymentMethods: string[];
  chargerConnectionType: string[];
  eConnection: string;
  isDiscoverable: boolean;
  updatedTimestamp: number;
  id: string;
  chargepoints: ChargePoint[];
  quarters: Quarter[];
  allSitesGeo: BasicGeo[];
  allSitesNames: string[];
  displayRevenue:boolean;
  displayUptime:boolean;
}



export interface BasicChargersTx {
  chargepoint: ChargePoint;
}

export interface Quarter {
  id: string;
  start: moment.Moment;
  end: moment.Moment;
  label: string;
  totalDays: number;
  financialData: FinancialData;
}

export interface FinancialData {
  totalRevenue: number;
  totalKwh: number;
  sessionsCount: number;
  totalSockets: number;
  kwhDay: number;
  avgKwhSocketDay: number;
  avgKwh: number;
  savedEVMiles: number;
  carbonSaved: number;
  avgUtilisationDay: number;
  avgSessionsDay: number;
  transactions: EVCTransaction[];
}
