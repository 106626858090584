import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Collapse,
  List,
} from '@mui/material';
import { IoIosInformationCircle } from 'react-icons/io';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedFinancialSite,
  fetchChargerSiteInfo,
  fetchSocketSiteInfo,
} from '../../store/financialSlice';
import {
  setSelectedHealthSite,
  fetchChargerUptimeData,
  fetchSocketUptimeData,
} from '../../store/healthSlice';
import { setQuarterLabel } from '../../store/uiSlice';

import { BasicSite } from '../../repositories/sites_repository/models/sites_repository_models';

import PortfolioSelect from './PortfolioSelect';
import SocketListItem from './components/SocketListItem';
import ChargepointListItem from './components/ChargepointListItem';
import { useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ChargerStatusKeyModal from './components/ChargerStatusKeyModal/ChargerStatusKeyModal';

export const SiteSelect = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const { portfolioFinancialData, siteData, selectedFinancialSite } = useSelector(
    (state) => state.sites,
  );
  const { portfolioHealthData, siteHealthData, selectedHealthSite } = useSelector(
    (state) => state.health,
  );
  const { viewSiteDetails } = useSelector((state) => state.ui);

  const [selectedCharger, setSelectedCharger] = useState(null);
  const [selectedSocket, setSelectedSocket] = useState(null);
  const [expandedCharger, setExpandedCharger] = useState(null);
  const [showChargerStatusKey, setShowChargerStatusKey] = useState(false);

  const [selectedSite, setSelectedSite] = useState(() => {
    switch (location.pathname) {
      case '/health':
        return selectedHealthSite;
      case '/':
      case '/summary':
        return selectedFinancialSite;
      default:
        return selectedFinancialSite;
    }
  });

  useEffect(() => {
    if (selectedFinancialSite && location.pathname === '/') {
      setSelectedSite(selectedFinancialSite);
    } else if (selectedHealthSite && location.pathname === '/health') {
      setSelectedSite(selectedHealthSite);
    }
  }, [
    location.pathname,
    portfolioFinancialData,
    portfolioHealthData,
    selectedFinancialSite,
    selectedHealthSite,
  ]);

  const handleSiteSelect = () => {
    const newHealthSite = new BasicSite(siteHealthData, null, null);
    const newFinancialSite = new BasicSite(siteData, null, null);
    dispatch(setSelectedHealthSite({ newSelectedSite: newHealthSite }));
    dispatch(setSelectedFinancialSite({ newSelectedSite: newFinancialSite }));
    setSelectedCharger(null);
    setSelectedSocket(null);

    dispatch(setQuarterLabel('All time'));
  };

  const handleChargerSelect = (charger, index) => {
    const newHealthSite = new BasicSite(siteHealthData, charger, null);
    const newFinancialSite = new BasicSite(siteData, charger, null);
    dispatch(fetchChargerUptimeData(newHealthSite));
    dispatch(fetchChargerSiteInfo(newFinancialSite));
    setSelectedSocket(null);
    setSelectedCharger(charger);
    dispatch(setQuarterLabel('All time'));
    handleExpandCharger(index);
  };

  const handleExpandCharger = (chargerIndex) => {
    if (viewSiteDetails) return;
    const selectedCharger = selectedSite.siteInfo.chargepoints[chargerIndex];

    const newHealthSite = new BasicSite(siteHealthData, selectedCharger, null);
    const newFinancialSite = new BasicSite(siteData, selectedCharger, null);
    dispatch(fetchChargerUptimeData(newHealthSite));
    dispatch(fetchChargerSiteInfo(newFinancialSite));
    setExpandedCharger(expandedCharger === chargerIndex ? null : chargerIndex);
    setSelectedCharger(selectedCharger);
    setSelectedSocket(null);
  };

  const handleSocketSelect = (socket) => {
    const newHealthSite = new BasicSite(siteHealthData, selectedCharger, socket);
    const newFinancialSite = new BasicSite(siteData, selectedCharger, socket);
    dispatch(fetchSocketUptimeData(newHealthSite));
    dispatch(fetchSocketSiteInfo(newFinancialSite));
    setSelectedSocket(socket);
    dispatch(setQuarterLabel('All time'));
  };

  const showChargerStatusKeyModal = () => {
    setShowChargerStatusKey(!showChargerStatusKey);
  };

  if (portfolioFinancialData && portfolioHealthData) {
    return <PortfolioSelect />;
  }

  if (
    (!portfolioHealthData && !siteHealthData) ||
    !selectedSite ||
    (!portfolioFinancialData && !siteData)
  ) {
    return (
      <div className="pl-12 pr-4">
        <div
          className="justify-start w-full px-5 pt-5 pb-4 bg-white border shadow rounded-2xl"
          style={{ minHeight: '160px', maxHeight: 'auto', overflowY: 'auto' }}
        >
          <div className="pb-2 text-lg font-customRegular">Chargers</div>
          <div className="pb-3 font-customRegular">Loading...</div>
        </div>
      </div>
    );
  }

  const allChargepoints = selectedSite?.siteInfo?.chargepoints;
  const allSockets = selectedSite?.siteInfo?.chargepoints?.map((e) => e.sockets).flat() ?? [];
  const socketsFromCharger =
    allSockets?.filter((e) => e.charger_name === selectedSite?.selectedCharger?.name.trim()) ?? [];
  const orderedSockets = socketsFromCharger.sort((a, b) => a.manual_code - b.manual_code);

  return (
    <div className="pl-12 pr-4 min-w-[28vw] ">
      <div
        className="justify-start w-full px-5 pt-5 pb-4 bg-white border shadow-lg rounded-2xl h-[60vh] "
        style={{ minHeight: '160px', maxHeight: 'auto', overflowY: 'auto' }}
      >
        <div className="flex flex-row items-center justify-center w-full">
          <div className="pb-2 text-lg font-customRegular">Chargers</div>
          <div className="flex-1" />
          <div
            className="flex flex-row items-center justify-center pb-2 hover:cursor-pointer "
            onClick={showChargerStatusKeyModal}
          >
            <IoIosInformationCircle size={24} color={theme.palette.btnActiveColor} />
            <div className="ml-2" />
            <p
              className="text-md"
              style={{
                color: theme.palette.btnActiveColor,
              }}
            >
              Key
            </p>
            {showChargerStatusKey && (
              <>
                <Modal
                  isOpen={showChargerStatusKey}
                  onRequestClose={showChargerStatusKeyModal}
                  style={{
                    overlay: {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      zIndex: 1000,
                    },
                    content: {
                      position: 'relative',
                      paddingBottom: '0px',
                      width: '300px',
                      height: '300px',
                      top: '55%',
                      left: '35%',
                      transform: 'translate(-50%, -50%)',
                      padding: '10px',
                      borderRadius: '16px',
                      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                >
                  <ChargerStatusKeyModal closeModal={showChargerStatusKeyModal} />
                </Modal>
              </>
            )}
          </div>
        </div>
        <div className="pb-3 text-md font-customRegular">Showing {allSockets.length} sockets</div>
        <TableContainer
          component={Paper}
          style={{
            maxHeight: 'auto',
            overflowY: 'auto',
            boxShadow: 'none',
          }}
          sx={{
            background: theme.palette.siteSelectColor,
            borderRadius: 3,
            '& .MuiTableCell-root': {
              borderBottom: 'none',
              border: 'none',
            },
          }}
        >
          <Table>
            <TableBody>
              <TableRow
                key={`${selectedSite.siteInfo.siteName}-row-${selectedSite.siteInfo.siteId}`}
                onClick={handleSiteSelect}
                sx={{ backgroundColor: '#EEEEEE' }}
              >
                <TableCell
                  key={`${selectedSite.siteInfo.siteName}-title-${selectedSite.siteInfo.siteId}`}
                  style={{
                    padding: '12px 0px 12px 10px',
                    cursor: 'pointer',
                    fontFamily: theme.typography.secondaryFontFamily,
                  }}
                >
                  {selectedSite.siteInfo.siteName}
                </TableCell>
              </TableRow>
              <List>
                {allChargepoints?.map((chargepoint, chargerIndex) => (
                  <>
                    <ChargepointListItem
                      isSelected={selectedCharger?.name === chargepoint.name}
                      chargepoint={chargepoint}
                      chargerIndex={chargerIndex}
                      isExpanded={expandedCharger === chargerIndex}
                      handleChargerSelect={handleChargerSelect}
                      handleExpandCharger={handleExpandCharger}
                    />

                    <Collapse in={expandedCharger === chargerIndex}>
                      <List>
                        {orderedSockets.length > 0 &&
                          orderedSockets.map((socket, socketIndex) => {
                            return (
                              <SocketListItem
                                isSelected={selectedSocket?.id === socket.id}
                                handleSocketSelect={handleSocketSelect}
                                socket={socket}
                                socketIndex={socketIndex}
                              />
                            );
                          })}
                      </List>
                    </Collapse>
                  </>
                ))}
              </List>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
