import { ChargePoint, ChargerSocket, BasicGeo, EVCUptime } from "../../models/models";

import moment from 'moment';


export class BasicHealthSite {
    siteInfo: HealthSite;
    selectedCharger?: ChargePoint;
    selectedSocket?: ChargerSocket;
    constructor(siteInfo: HealthSite, selectedCharger?: ChargePoint, selectedSocket?: ChargerSocket) {
      this.siteInfo = siteInfo;
      this.selectedCharger = selectedCharger;
      this.selectedSocket = selectedSocket;
    }
  }

  export interface PortfolioHealthSites {
    start: moment.Moment;
    end: moment.Moment;
    label: string;
    totalDays: number;
    quarters: HealthQuarter[];
    sites: HealthSite[];
    description: string;
    sitesGeo: BasicGeo[];
    displayUptime:boolean;
  }
  

  export interface HealthSite {
    siteName: string;
    geo: BasicGeo;
    siteAddress1: string;
    sitePostcode: string;
    isPublic: boolean;
    client: string;
    model: string;
    siteCrm: string;
    siteType: string;
    timestamp: number;
    clientContactPerson: string;
    clientContactNo: string;
    clientContactEmail: string;
    siteAddress2: string;
    siteCounty: string;
    baysAtLocation: number;
    chargerPowerRates: number[];
    chargerSpeed: string[];
    hasAvailableCharger: boolean;
    chargerPaymentMethods: string[];
    chargerConnectionType: string[];
    eConnection: string;
    isDiscoverable: boolean;
    updatedTimestamp: number;
    id: string;
    chargepoints: ChargePoint[];
    quarters: HealthQuarter[];
    allSitesGeo: BasicGeo[];
    allSitesNames: string[];
    displayUptime:boolean;
  }

  export interface HealthQuarter {
    id: string;
    start: moment.Moment;
    end: moment.Moment;
    label: string;
    totalDays: number;
    uptimeData: UptimeData;
  }
  
  export interface UptimeData {
    availableAvg: number;
    chargingAvg: number;
    downtimeAvg: number;
    faultedAvg: number;
    finishingAvg: number;
    preparingAvg: number;
    suspendedEVAvg: number;
    suspendedEVSEAvg: number;
    totalAvailableStatus: number;
    totalChargingStatus: number;
    totalFaultedStatus: number;
    totalFinishingStatus: number;
    totalPreparingStatus: number;
    totalSuspendedEVStatus: number;
    totalSuspendedEVSEStatus: number;
    totalUnavailableStatus: number;
    totalUnknownStatus: number;
    avgUptimePercentage: number;
    avgDowntimePercentage: number;
    unavailableAvg: number;
    unknownAvg: number;
    uptimeAvg: number;
    uptimes: EVCUptime[];
  }
  