import React from 'react';

import { IoIosClose } from 'react-icons/io';

import PropTypes from 'prop-types';
import StatusKeyItem from './StatusKeyItem';
import {  useTheme } from '@mui/material';

export default function ChargerStatusKeyModal({ closeModal }) {
  const theme = useTheme();

  return (
    <div className="flex flex-col items-start justify-start w-full px-4 py-1 ">
      <div className="flex flex-row items-start justify-between w-full">
        <p className="text-lg font-bold">Key</p>
        <IoIosClose onClick={closeModal} className="hover:cursor-pointer" size={24}  color={theme.palette.btnActiveColor}/>
      </div>
      <div className="h-6" />
      <StatusKeyItem chargerStatus="AVAILABLE" keyDescription="Available" />
      <StatusKeyItem chargerStatus="PREPARING" keyDescription="Busy" />
      <StatusKeyItem chargerStatus="CHARGING" keyDescription="Charging" />
      <StatusKeyItem chargerStatus="FAULTED" keyDescription="Offline / Fault Detected" />
      <div className="h-5" />
      <div onClick={closeModal} className="flex justify-end w-full">
        <p className="text-md hover:cursor-pointer" style={{
          color: theme.palette.btnActiveColor,
        }}> Close</p>
      </div>
    </div>
  );
}

ChargerStatusKeyModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
