import { createTheme } from '@mui/material/styles';

let primaryFontFamily = 'ConduitITC-Bold, sans-serif';
let secondaryFontFamily = 'ConduitITC-Regular, sans-serif';
let white = '#000';
let primaryColorTxt = '#000';
let dropdownColor = '#000';
let btnBackGround = '#F1F1F2';
let siteSelectbg = '#F1F1F2';
let hoverBtn = '#fcdb38';
let navUnderline = '#000';

const themeNCP = createTheme({
  palette: {
    button: {
      main: primaryColorTxt,
    },
    btnBackgroundColor: btnBackGround,
    btnActiveColor: primaryColorTxt,
    btnHoverColor: hoverBtn,
    btnHoverTxt: white,
    dropdownColor: dropdownColor,
    underline: navUnderline,
    siteSelectColor: siteSelectbg,
  },
  typography: {
    fontFamily: primaryFontFamily,
    secondaryFontFamily: secondaryFontFamily,
    fontSize: 16,
    htmlFontSize: 16,
  },
});
export default themeNCP;
