import axios from 'axios';

import { ChargerBasicStatus } from './models/chargers_repository_models';

const prodBaseUrl = 'https://europe-west2-prod-evc-app.cloudfunctions.net';
const devBaseUrl = 'https://europe-west2-dev-evc-app-4d345.cloudfunctions.net'
const baseUrl = process.env.REACT_APP_ENV === 'development' ? devBaseUrl : prodBaseUrl

export abstract class ChargersRepository {
  static async getPortfolioChargersStatus(pid: string, token: string): Promise<Array<ChargerBasicStatus>> {
    try {
      const response = await axios.get(
        `${baseUrl}/evc_data/client_chargers_status?pid=${pid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      
      return response.data as Array<ChargerBasicStatus>;
    } catch (error) {
      console.log(error);
      throw Error('There was an error getting the portfolio data');
    }
  }

  static async getSiteChargersStatus(sid: string, token: string): Promise<Array<ChargerBasicStatus>> {
    try {
      const response = await axios.get(
        `${baseUrl}/evc_data/client_chargers_status?sid=${sid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data as Array<ChargerBasicStatus>;
    } catch (error) {
      console.log(error);
      throw Error('There was an error getting the portfolio data');
    }
  }
}
